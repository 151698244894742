import { Col, Row } from 'antd';
import ColumnTitle from 'components/ColumnTitle';
import { COLUMN_TYPES } from 'core/utils/constants';
import { difference } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { getCustomFieldsByKeysForReviews } from 'redux/selectors/customFields';
import { addColumn } from 'redux/ui/clientInteractions/reducer';
import { AddColumnIcon, ColumnPreview, SettingsRow } from './styled';

const AddColumn = ({ tableColumns, addColumn, customFieldsByKeys = {} }) => {
  return (
    <SettingsRow>
      <Col span={24}>
        {difference(
          [...Object.keys(COLUMN_TYPES), ...Object.keys(customFieldsByKeys)],
          tableColumns
        ).map(column => (
          <Row key={column} gutter={[8, 8]} style={{ margin: '-4px 0' }}>
            <ColumnPreview span={24} onClick={() => addColumn(column)}>
              <ColumnTitle
                columnType={column}
                customFieldsByKeys={customFieldsByKeys}
                width="80%"
              />
              <AddColumnIcon />
            </ColumnPreview>
          </Row>
        ))}
      </Col>
    </SettingsRow>
  );
};

const mapStateToProps = state => {
  return {
    customFieldsByKeys: getCustomFieldsByKeysForReviews(state, true),
    tableColumns: state.uiClientInteractions.tableColumns
  };
};

const mapDispatchToProps = {
  addColumn
};

export default connect(mapStateToProps, mapDispatchToProps)(AddColumn);
