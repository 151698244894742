export default {
  messages: {
    success: {
      appealSended: 'Appeal sent',
      appealCanceled: 'Appeal canceled',
      appealDeleted: 'Appeal deleted',
      processingCompleted: 'Processing completed',
      commentSaved: 'Comment Saved',
      commentAdded: 'Comment added',
      answerSended: 'Reply sended',
      templateUpdated: 'Template updated',
      templateAdded: 'Template added',
      templateDeleted: 'Template deleted',
      integrationCompleted: 'Integration complete',
      filtersApplied: 'Filters applied',
      newTokenGenerated: 'New token generated',
      userMoved: 'The user is successfully updated',
      taskStopped: 'The task is stopped',
      taskCompleted: 'Task is finished',
      configurationDeleted: 'Configuration deleted',
      repeatingCanceled: 'Repetitions stopped',
      settingsUpdated: 'Parameters updated',
      transcriptionSent: 'The communication has been sent for transcription'
    },
    warning: {
      failedTemplateUpdate: 'Failed to update template',
      failedTemplateSave: 'Failed to save template',
      failedSettingsUpdate: 'Failed to update parameters',
      limitExportReviews:
        'You are trying to download over 5000 evaluations. Shorten the period to reduce the number of exported evaluations.'
    },
    error: {
      failedInstallIntegration: 'Integration failed to complete. Contact us at info@qolio.io',
      failedRegisterOrganization:
        'Failed to register the organization. Please try again or email us at info@qolio.io',
      failedMoveUser: 'Failed to update user',
      failedStopTask: 'Failed to stop task',
      failedDeleteConfiguration: 'Failed to delete configuration',
      failedStopRepeating: 'Failed to stop repeating',
      failedCloseTask: 'Failed to complete task'
    }
  }
};
