import { isEmpty, isEqual, pick, pickBy } from 'lodash';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getAppellationsByIds } from 'redux/selectors/appeals';
import { getCurrentUser } from 'redux/selectors/users';

import Question from './Question';

const defaultQuestions = [];

const QuestionsContainer = ({
  communicationId,
  reviewId,
  questions = defaultQuestions,
  customCommunicationView,
  checklistDefinition,
  onAutoFail,
  disabled,
  comments,
  onDeleteComment,
  onUpdateComment,
  allowCommenting,
  handleCommentSave,
  communication
}) => {
  const {
    questionIdToAnswerValue,
    questionIdToStandardComment,
    questionIdToAppealObjection,
    appealId,
    questionIdToAppealComment
  } = useSelector(
    state =>
      pick(state.uiChecklistManager, [
        'questionIdToAnswerValue',
        'questionIdToStandardComment',
        'questionIdToAppealObjection',
        'questionIdToAppealComment',
        'appealId'
      ]),
    isEqual
  );

  const appeal = useSelector(state => getAppellationsByIds(state)[appealId], isEqual);
  const currentUser = useSelector(getCurrentUser, isEqual);
  const canEditForAppeal =
    appeal?.assignedToId === currentUser?.id && !appeal?.questionObjectionsProcessed;

  const getDisabledState = id =>
    canEditForAppeal && questionIdToAppealObjection[id] ? false : disabled;

  const hasComments = useCallback(
    id => !isEmpty(pickBy(comments, comment => comment?.metadata?.questionId === id)),
    [comments]
  );

  return questions.map(
    ({ id, name, ratingValues, ratingValuesViews, binding, standardComments, colorZones }) => (
      <Question
        communicationId={communicationId}
        reviewId={reviewId}
        customCommunicationView={customCommunicationView}
        disabled={getDisabledState(id)}
        hasComments={hasComments(id)}
        key={id}
        onAutoFail={onAutoFail}
        selectedStandardComments={questionIdToStandardComment[id]}
        value={questionIdToAnswerValue[id]}
        appealId={appealId}
        appealObjection={questionIdToAppealObjection[id]}
        appealComment={questionIdToAppealComment[id]}
        checklistDefinition={checklistDefinition}
        id={id}
        name={name}
        questionComments={pickBy(comments, comment => comment?.metadata?.questionId === id)}
        onDeleteComment={onDeleteComment}
        onUpdateComment={onUpdateComment}
        allowCommenting={allowCommenting}
        onCommentSave={handleCommentSave}
        ratingValues={ratingValues}
        ratingValuesViews={ratingValuesViews}
        binding={binding}
        standardComments={standardComments}
        colorZones={colorZones}
        communication={communication}
      />
    )
  );
};

export default React.memo(QuestionsContainer, isEqual);
