import { Alert, Button, Col, Modal, Row } from 'antd';
import { SYNC_DATA_PERIODS } from 'core/utils/constants';
import { pick, isEqual } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { setCurrentBackgroundJob } from 'redux/entities/backgroundJob/reducer';
import { userAccountBindingsResource } from 'redux/resources/userAccountBindings';
import { setCurrentStep } from 'redux/ui/registerFromIntegration/reducer';
import styled from 'styled-components';
import SelectUsers from './components/SelectUsers';
import SyncBackgroundJob from './components/SyncBackgroundJob';
import SyncData from './components/SyncData';

const importAccounts = userAccountBindingsResource.operations.importSaas;

const stepsContent = {
  users: SelectUsers,
  sync: SyncData,
  syncBackgroundJob: SyncBackgroundJob
};

const Container = styled(Col)`
  background: white;
  padding: 16px;
`;

const RegisterFromIntegration = ({ visible, onProceed, fetchIntegrationData }) => {
  const { id: routeIntegrationId } = useParams();
  const {
    selectedUsersIds = [],
    currentStep,
    syncFrom,
    integrationId: stateIntegrationId
  } = useSelector(
    state =>
      pick(state.uiRegisterFromIntegration, [
        'integrationId',
        'syncFrom',
        'currentStep',
        'selectedUsersIds'
      ]),
    isEqual
  );
  
  const integrationId = stateIntegrationId || routeIntegrationId;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const steps = {
    users: {
      name: 'users',
      modalTitle: 'Пользователи',
      proceed: async () => {
        try {
          await dispatch(
            importAccounts({
              id: integrationId,
              data: selectedUsersIds.map(id => ({ user_account_binding_id: id }))
            })
          );
          dispatch(setCurrentStep('sync'));
        } catch (error) {
          setLoading(false);
        }
      },
      stepText: 'Шаг 1',
      // TODO : move alert to final render
      content: SelectUsers,
      alert: 'Для выбранных пользователей будет произведена синхронизация данных',
      description: (
        <div>
          <span>
            При необходимости вы сможете дополнительно синхронизировать данные по другим
            пользователям в настройках интеграции.
          </span>
        </div>
      )
    },
    sync: {
      name: 'sync',
      modalTitle: 'Выберите диапазон синхронизации данных',
      proceed: () => {
        const { number, momentUnit } = SYNC_DATA_PERIODS[syncFrom];

        const fetchFromISO = moment()
          .subtract(number, momentUnit)
          .toISOString();

        try {
          const backgroundJob = fetchIntegrationData({
            id: integrationId,
            fetchFrom: fetchFromISO
          });
          if (!!backgroundJob) {
            dispatch(setCurrentBackgroundJob(backgroundJob));
            dispatch(setCurrentStep('syncBackgroundJob'));
          }
        } catch (error) {
          setLoading(false);
        }
      },
      stepText: 'Шаг 2',
      content: SyncData,
      alert: 'Пожалуйста задайте диапазон для синхронизации данных',
      description: (
        <div>
          <span>
            Обратите внимание, чем больше заданный диапазон, тем больше времени займет
            синхронизация. Вы всегда сможете увеличить диапазон синхронизируемых данных в настройках
            интеграции.
          </span>
        </div>
      )
    },
    syncBackgroundJob: {
      name: 'syncBackgroundJob',
      modalTitle: 'Идёт синхронизация данных',
      proceed: onProceed
    }
  };
  
  const proceed = () => {
    if (currentStep === 'sync') {
      history.push('/user/' + localStorage.getItem("curUser") + '/integrations-settings/amocrm/' + integrationId);
      window.location.reload();
    } else {
      setLoading(true);
      steps[currentStep].proceed();
      setLoading(false);
    }
  };

  const Content = stepsContent[currentStep];
  const { modalTitle, alert, description } = steps[currentStep];
  
  return (
    <>
      <Modal
        destroyOnClose
        closable={currentStep === 'syncBackgroundJob'}
        maskClosable={currentStep === 'syncBackgroundJob'}
        visible={visible}
        title={modalTitle}
        onCancel={proceed}
        footer={[
          <Button type="primary" loading={loading} onClick={proceed}>
            Продолжить
          </Button>
        ]}
        style={{ width: '80%', minWidth: currentStep !== 'syncBackgroundJob' ? '780px' : 'auto' }}
      >
        <Container>
          {alert && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Alert message={alert} description={description || ''} type="info" />
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Content proceed={proceed} />
            </Col>
          </Row>
        </Container>
      </Modal>
    </>
  );
};

export default RegisterFromIntegration;
