import api from 'core/api';
import { get, isEmpty, isNil, omit } from 'lodash';
import decamilize from 'decamelize-keys-deep';
import { isUUID } from 'core/utils/isUUID';
import { clearComments, clearCommentsToSave, startClear } from '../checklistEditor/reducer';

const createReviewWithNestedAttributes = ({
  clientInteractionId,
  customCommunicationAttributes,
  questionsWithValuesAndBindings,
  currentCalibrationSessionId = null
}) => async (dispatch, getState) => {
  dispatch(startClear(true));
  const state = getState();

  const reviewState = state.uiClientInteractionPage;
  const { operatorId } = reviewState;
  const comments = Object.values(reviewState.commentsByIds);
  const { commentsToSave } = state.uiChecklistEditor;
  const taskDefinitions = get(reviewState, 'tasks.taskDefinitionsIds', []).reduce(
    (acc, taskDefinitionId) => {
      return [...acc, { taskDefinitionId }];
    },
    []
  );

  const commentsWithoutId = comments.map(item => {
    if (isUUID(item.id)) return item;
    return omit(item, 'id');
  });

  const commentsToSaveWithoutId = Object.values(commentsToSave).map(item => {
    if (isUUID(item.id)) return item;
    return omit(item, 'id');
  });

  const commentsAttributes = [...commentsWithoutId, ...commentsToSaveWithoutId];

  const checklist = state.uiChecklistManager.currentChecklist;
  const calibrationSessionsResource = state.calibrationSessionsResource.byIds;
  const currentCalibrationSession = Object.values(calibrationSessionsResource).find(
    item => item.id === currentCalibrationSessionId
  );
  const { questionIdToAnswerValue } = state.uiChecklistManager;
  const { questionIdToStandardComment } = state.uiChecklistManager;
  const { checklistDefinitionId } = checklist;
  const { comment } = checklist;
  const checklistMetadata = checklist.metadata || {};

  const answers = Object.keys(questionIdToAnswerValue).reduce((result, questionId) => {
    const value = questionIdToAnswerValue[questionId];
    const standardComments = questionIdToStandardComment[questionId];

    if (isNil(value)) {
      return result;
    }

    return [...result, { questionId, value, standardComments }];
  }, []);

  questionsWithValuesAndBindings.forEach(question => {
    if (answers.find(item => item.questionId === question.id)) {
      return;
    }
    answers.push({
      question_id: question.id,
      standard_comments:
        Object.values(question.standardComments).length === 0
          ? undefined
          : question.standardComments,
      value: null
    });
  });

  const payloadForCallOrChatReview = decamilize({
    clientInteractionId,
    operatorId,
    commentsAttributes,
    // commentsAttributes: [...commentsWithoutId],
    tasksAttributes: [...taskDefinitions],
    checklistAttributes: {
      checklistDefinitionId,
      answers,
      comment,
      checklistMetadata
    },
    calibration_session_id: currentCalibrationSession?.id ? currentCalibrationSession?.id : null
  });

  const payloadForCustomCommunicationReview = decamilize({
    customCommunicationAttributes,
    operatorId: customCommunicationAttributes?.operatorId,
    commentsAttributes: [...comments],
    tasksAttributes: [...taskDefinitions],
    checklistAttributes: {
      checklistDefinitionId,
      answers,
      comment,
      checklistMetadata
    },
    calibration_session_id: currentCalibrationSession?.id ? currentCalibrationSession?.id : null
  });

  const review = await api.createNestedReview(
    isEmpty(customCommunicationAttributes)
      ? payloadForCallOrChatReview
      : payloadForCustomCommunicationReview
  );

  await dispatch(clearCommentsToSave());
  await dispatch(clearComments());
  await dispatch(startClear(false));
  return review;
};

export default createReviewWithNestedAttributes;
