import React, {useContext, useLayoutEffect, useRef, useState } from 'react';
import { Col, Radio, Row, Tooltip } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import WidgetTable from 'components/Dashboard/WidgetTable';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, isEqual, keyBy } from 'lodash';
import { IconButton } from 'components/Buttons/IconButton';
import ExcelIcon from 'images/ExcelIcon';
import moment from 'moment';
import { getChecklistDefinitionQuestionGroupsBeforeQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import SRow from 'components/Standard/SRow';
import { LargeWidgetCard } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  exportWidgetData,
  widgetInfoTextSetter,
  widgetNameSetter,
  widgetPeriodSetter
} from '../../utils';
import { prepareColumns, prepareTableData } from './prepareTableData';
import EmptyWidget from '../../Components/EmptyWidget';
import { ContextExportDashboards } from '../../DashboardPage';
import WidgetViewLastUpdate from '../../../../components/Dashboard/WidgetViewLastUpdate';

const TableChecklistItemsHistory = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, type, filters, name } = widgetData;
  const [loadingExport, setLoadingExport] = useState(false);

  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const checklistDefinitionId = get(widgetData, 'filters.checklistDefinitionsIds');
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const currentChecklist = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const checklistItems = useSelector(
    state => getChecklistDefinitionQuestionGroupsBeforeQuestions(state, currentChecklist),
    isEqual
  );

  const widgetValue = get(widgetsAnalyticsByIds, id, {});
  const metadata = widgetValue?.metadata;
  const timeFrom = metadata?.time_from;
  const timeTo = metadata?.time_to;

  const checklistDefinitionAverageScore = get(
    widgetValue,
    'checklist_definition_average_score',
    []
  );

  const averageScoreByQuestions = keyBy(get(widgetValue, 'average_score_by_questions', []), 'id');

  const reviewsCountHistory = get(widgetValue, 'reviews_count_history', {});

  const averageScoreByQuestionsHistory = keyBy(
    get(widgetValue, 'average_score_by_questions_history', []),
    'id'
  );

  const averageScoreByQuestionGroupsHistory = keyBy(
    get(widgetValue, 'average_score_by_question_groups_history', []),
    'id'
  );

  const checklistDefinitionAverageScoreHistory = get(
    widgetValue,
    'checklist_definition_average_score_history',
    []
  );

  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds, {});

  const colorZones = get(checklistDefinition, 'colorZones', {});

  const tableColumns = prepareColumns({
    filters,
    timeFrom,
    timeTo,
    colorZones,
    checklistDefinition
  });

  const tableRows = prepareTableData({
    filters,
    timeFrom,
    timeTo,
    colorZones,
    usersByIds,
    checklistItems,
    checklistDefinition,
    reviewsCountHistory,
    checklistDefinitionAverageScore,
    checklistDefinitionAverageScoreHistory,
    averageScoreByQuestions,
    averageScoreByQuestionsHistory,
    averageScoreByQuestionGroupsHistory
  });

  const isEmptyWidgetData = isEmpty(
    Object.values(
      filters.viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
        ? averageScoreByQuestionsHistory
        : averageScoreByQuestionGroupsHistory
    ).filter(item => !isEmpty(item.history))
  );

  const body = {
    widgetType: type,
    widgetData: {
      columns: tableColumns.map(item => ({
        ...item,
        title:
          item.key === 'name' || item.key === 'totalScore'
            ? t(item?.title?.props?.i18nKey)
            : moment(item.key).format('DD/MM')
      })),
      rows: tableRows.map(item => ({
        ...item,
        name: item.key === 'footer' || item.key === 'header' ? t(item.name) : item.name
      }))
    }
  };
  const { addElementExportWidget } = useContext(ContextExportDashboards);
  const prevBodyRef = useRef(null);

  useLayoutEffect(() => {
    if (prevBodyRef.current !== body && (!isEmptyWidgetData && !isWarningWidget)) {
      prevBodyRef.current = body;
      addElementExportWidget({ ...prevBodyRef.current, id });
    }
  }, [body]);

  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <SRow type="flex" justify="space-between" align="top">
              <SCol span={9}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter({
                    widgetsFilters: filters,
                    widgetType: type,
                    showDateMode: true
                  })}
                </TimePeriod>
              </SCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e =>
                        dispatch(
                          updateDashboardWidget({
                            id,
                            type,
                            filters: { ...filters, viewMode: e.target.value }
                          })
                        )
                      }
                      value={filters.viewMode || WIDGET_VIEW_MODES.QUESTIONS.value}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.QUESTIONS.value}>
                        {t(WIDGET_VIEW_MODES.QUESTIONS.title)}
                      </Radio.Button>
                      <Radio.Button
                        value={WIDGET_VIEW_MODES.GROUPS.value}
                        disabled={!checklistDefinition.isGroupable}
                      >
                        {t(WIDGET_VIEW_MODES.GROUPS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SCol display="flex" justifyContent="flex-end" span={8} alignItems="center">
                <IconButton
                  tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                  button={{
                    icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                    marginTop: '2px',
                    onClick: () =>
                      loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                    size: 'icon'
                  }}
                />
                <SettingsModal widgetData={widgetData} />
                <WidgetViewLastUpdate
                  widgetData={widgetData}
                  dateLastUpdate={widgetValue?.dateLastUpdate}
                />
              </SCol>
            </SRow>
            <WidgetTable
              columns={tableColumns}
              dataSource={tableRows}
              pagination={false}
              size="small"
              bordered
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
            />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableChecklistItemsHistory;
