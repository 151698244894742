import { createReduxResource } from 'core/redux/resources';
import { assignChildrenToParents } from '../../core/utils';

export const userCustomFieldsResource = createReduxResource({
  name: 'userCustomFields',
  reactors: {
    onLoadSucceed: ({ resources, dispatch, getState, actions }) => {
      const userCustomFieldsWithChildren = assignChildrenToParents({
        nodesByIds: resources
      });
      return dispatch(actions.onLoadSucceed(userCustomFieldsWithChildren));
    }
  },
  additionalReducer: {
    onLoadSucceed: (state, { payload }) => ({
      ...state,
      loading: false,
      byIds: { ...payload }
    })
  }
});
