import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Typography } from 'antd';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import RegisterFromIntegration from 'components/RegisterFromIntegration';
import SCard from 'components/Standard/SCard';
import BitrixService from 'core/services/bitrix';
import { get, isEmpty } from 'lodash';
import BackgroundJob from 'pages/BackgroundJob';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { saveBackgroundJob } from 'redux/entities/backgroundJob/operations';
import {
  registerWithBitrix,
  syncDataWithBitrix
} from 'redux/entities/bitrixIntegration/operations';
import {
  setShowBJModal,
  setShowUsersModal,
  setIntegrationId
} from 'redux/ui/registerFromIntegration/reducer';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const RegisterPage = ({
  syncDataWithBitrix,
  registerWithBitrix,
  showUsersModal,
  setShowUsersModal,
  showBJModal,
  setShowBJModal,
  setIntegrationId
}) => {
  // FIXME : move form to wrapper
  const [user, setUser] = useState({});
  const [organization, setOrganization] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const checkCurrentIntegration = async () => {
    const { INSTALLED } = await BitrixService.getAppInfo();

    if (!INSTALLED) {
      return Modal.confirm({
        title: 'Похоже у вас не установленно наше приложение',
        content: 'У вас уже есть организация в Qolio или вы регистрируете новую?',
        okText: 'Новую',
        cancelText: 'Уже есть',
        okType: 'primary',
        onCancel: () => history.push('/login')
      });
    }
  };

  useEffect(() => {
    checkCurrentIntegration();
    BitrixService.getCurrentUserData(setUser);
    BitrixService.getUserOrganization(setOrganization);
  }, []);

  const register = async params => {
    setLoading(true);
    const integration = await registerWithBitrix({
      ...params,
      id: user.ID,
      firstName: user.NAME,
      lastName: user.LAST_NAME,
      phoneNumber: user.PERSONAL_PHONE
    });

    if (!isEmpty(integration) && integration?.currentBackgroundJob?.id) {
      setIntegrationId(integration.id);
      setLoading(false);
      setShowBJModal(true);
      BitrixService.setOption('backgroundJob', integration?.currentBackgroundJob);
    } else {
      setLoading(false);
      message.error(t('messages.error.failedRegisterOrganization'));
    }
  };

  const onBackgroundJobDone = () => {
    setShowBJModal(false);
    setShowUsersModal(true);
    BitrixService.setOption('backgroundJob', undefined);
    BitrixService.setOption('usersSelected', false);
  };

  const onBackgroundJobFailed = () => {
    Modal.error({
      title: 'Возникла ошибка при синхронизации данных с Битрикс24',
      content: 'Пожалуйста, переустановите приложение или напишите нам на info@qolio.io',
      onOk: () => {
        setShowBJModal(false);
        BitrixService.setOption('backgroundJob', undefined);
        localStorage.clear();
      }
    });
  };

  const onFinishRegister = () => {
    BitrixService.setOption('usersSelected', true, () => {
      BitrixService.finishInstall();
    });
  };

  const RegisterForm = ({ register }) => {
    const [form] = Form.useForm();

    const onFinishFailed = err => {
      setLoading(false);
    };

    return (
      <Form
        form={form}
        name="register"
        initialValues={{ email: user?.EMAIL, organization: organization?.NAME, agreement: false }}
        onFinishFailed={onFinishFailed}
        onFinish={register}
        scrollToFirstError
      >
        <span>{`Приветствуем, ${get(user, 'NAME', 'новый пользователь')}`}</span>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Неправильный email'
            },
            {
              required: true,
              message: 'Введите email'
            }
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="organizationName"
          rules={[
            {
              required: true,
              message: 'Введите название организации'
            }
          ]}
        >
          <Input placeholder="Название организации" />
        </Form.Item>

        <Form.Item
          name="password"
          hasFeedback
          rules={[
            { required: true, message: 'Введите пароль' },
            { min: 6, message: 'Минимум 6 символов' }
          ]}
        >
          <Input.Password placeholder="Пароль" />
        </Form.Item>

        <Form.Item
          name="confirm"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Введите пароль ещё раз'
            },
            { min: 6, message: 'Минимум 6 символов' },
            {
              validator: (rule, value) => {
                if (!value || form.getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject('Пароли не совпадают');
              }
            }
          ]}
          dependencies={['password']}
        >
          <Input.Password placeholder="Повторите пароль" />
        </Form.Item>

        <Form.Item
          name="agreement"
          rules={[
            {
              required: true,
              transform: value => value || undefined,
              type: 'boolean',
              message: 'Для регистрации необходимо ознакомиться с соглашениеми'
            }
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            {`Я согласен(на) с `}
            <a target="_blank" rel="noopener noreferrer" href="https://qolio.ru/offer">
              договором публичной оферты
            </a>
            {` и `}
            <a target="_blank" rel="noopener noreferrer" href="https://qolio.ru/privacy_policy">
              политикой конфиденциальности
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Зарегистрировать Организацию
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Col span={24}>
      <Row type="flex" align="middle" justify="center" style={{ height: '100%' }}>
        <Col {...{ sm: { span: 12 }, md: { span: 10 }, xxl: { span: 8 } }}>
          <SCard shadowed>
            <Row justify="space-between" type="flex" align="middle">
              <Col>
                <Title level={3}>Регистрация c Битрикс24</Title>
              </Col>
              <Col>
                <Link to="/login">Войти</Link>
              </Col>
            </Row>
            <RegisterForm register={register} />
          </SCard>

          <RegisterFromIntegration
            visible={showUsersModal}
            onProceed={onFinishRegister}
            fetchIntegrationData={syncDataWithBitrix}
          />
          <Modal
            visible={showBJModal}
            footer={null}
            closable={false}
            maskClosable
            // maskClosable={false}
          >
            <BackgroundJob
              text={
                <video autoPlay loop muted playsinline>
                  <source src={catWithSpinner} type="video/mp4" />
                </video>
              }
              spinning={false}
              onDone={onBackgroundJobDone}
              onFailed={onBackgroundJobFailed}
            />
          </Modal>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  currentBackgroundJob: state.backgroundJob.currentBackgroundJob,
  showBJModal: state.uiRegisterFromIntegration.showBJModal,
  showUsersModal: state.uiRegisterFromIntegration.showUsersModal
});

const mapDispatchToProps = {
  registerWithBitrix,
  saveBackgroundJob,
  setShowBJModal,
  setShowUsersModal,
  syncDataWithBitrix,
  setIntegrationId
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPage));
