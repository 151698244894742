import { DeleteOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row, Typography } from 'antd';
import ColumnTitle from 'components/ColumnTitle';
import { COLUMN_TYPES } from 'core/utils/constants';
import { get, isEmpty, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { communicationTablesResource } from 'redux/resources/communicationTables';
import { getCustomFieldsByKeysForReviews } from 'redux/selectors/customFields';
import {
  deleteColumn,
  moveTableColumn,
  toogleVisibleAddColumnBlock
} from 'redux/ui/clientInteractions/reducer';
import AddColumn from './AddColumn';
import { Column, CreateButton, MoreRowInfoButton, SettingsRow } from './styled';

const Columns = ({
  toogleVisibleAddColumnBlock,
  isVisibleAddColumnBlock,
  tableId,
  currentUserId,
  uiTableColumns,
  currentTableColumns,
  updateCommunicationTable,
  deleteColumn,
  moveTableColumn,
  communicationTablesByIds,
  customFieldsByKeys
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!isEmpty(uiTableColumns) && currentTableColumns !== uiTableColumns)
      updateCommunicationTable({
        id: tableId,
        columns: uiTableColumns
      });
  }, [uiTableColumns]);

  const { Text } = Typography;

  const tableCreatorId = isNil(communicationTablesByIds[tableId])
    ? ''
    : communicationTablesByIds[tableId]?.creatorId;

  const allowEdit = tableCreatorId === currentUserId;

  const onDragEnd = ({ source, destination }) => {
    if (!destination) return;
    moveTableColumn({ from: source.index, to: destination.index });
  };

  const customFieldKeysSet = new Set(Object.keys(customFieldsByKeys));

  const filteredTableColumns = uiTableColumns.filter(column => {
    return customFieldKeysSet.has(column) || !column.startsWith('custom_field/');
  });

  return (
    <>
      {!isEmpty(communicationTablesByIds) ? (
        <SettingsRow>
          <Row type="flex" align="middle" justify="space-between" style={{ height: '30px' }}>
            <Text strong>{t('clientInteractionsPage.drawer.columns.title')}</Text>
          </Row>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="top-container" direction="vertical" type="container">
              {(provided, { isDraggingOver }) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {filteredTableColumns.map((column, i) =>
                    column === COLUMN_TYPES.communicationType.value ? (
                      <Row
                        key={column}
                        type="flex"
                        align="middle"
                        justify="space-between"
                        gutter={[8, 8]}
                        // style={{ margin: '-4px' }}
                      >
                        <Column span={22}>
                          <ColumnTitle
                            columnType={column}
                            customFieldsByKeys={customFieldsByKeys}
                          />
                        </Column>
                      </Row>
                    ) : (
                      <Draggable index={i} key={column} draggableId={column}>
                        {(provided, { isDragging }) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Row
                              type="flex"
                              align="middle"
                              justify="space-between"
                              gutter={[8, 8]}
                              style={{ margin: '-4px' }}
                            >
                              <Column span={22} isDragging={isDragging}>
                                <ColumnTitle
                                  columnType={column}
                                  customFieldsByKeys={customFieldsByKeys}
                                />
                              </Column>
                              <Col>
                                {allowEdit && !isDraggingOver ? (
                                  <Popconfirm
                                    cancelButtonProps={{ fontSize: '14px', size: 'middle' }}
                                    okButtonProps={{ fontSize: '14px', size: 'middle' }}
                                    title={t(
                                      'clientInteractionsPage.drawer.columns.confirmDeleteColumn.title'
                                    )}
                                    placement="topRight"
                                    okText={t(
                                      'clientInteractionsPage.drawer.columns.confirmDeleteColumn.ok'
                                    )}
                                    cancelText={t(
                                      'clientInteractionsPage.drawer.columns.confirmDeleteColumn.cancel'
                                    )}
                                    onConfirm={() => deleteColumn(column)}
                                  >
                                    <MoreRowInfoButton icon={<DeleteOutlined />} />
                                  </Popconfirm>
                                ) : (
                                  <MoreRowInfoButton
                                    icon={<DeleteOutlined />}
                                    disabled
                                    style={{ visibility: 'hidden' }}
                                  />
                                )}
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <CreateButton
            block
            onClick={toogleVisibleAddColumnBlock}
            disabled={tableCreatorId !== currentUserId}
          >
            {isVisibleAddColumnBlock
              ? t('clientInteractionsPage.drawer.columns.buttons.hidePanel')
              : t('clientInteractionsPage.drawer.columns.buttons.viewAddColumnPanel')}
          </CreateButton>
          {isVisibleAddColumnBlock ? <AddColumn /> : null}
        </SettingsRow>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {
  const { tableId, tableColumns, isVisibleAddColumnBlock } = state.uiClientInteractions;
  const communicationsTablesByIds = state.communicationTablesResource.byIds;
  const currentTableColumns = get(communicationsTablesByIds, `${tableId}.columns`, []);
  const customFieldsByKeys = getCustomFieldsByKeysForReviews(state, true);

  return {
    tableId,
    uiTableColumns: tableColumns,
    currentTableColumns,
    isVisibleAddColumnBlock,
    currentUserId: state.reduxTokenAuth.currentUser.attributes.id,
    customFieldsByKeys
  };
};

const mapDispatchToProps = {
  toogleVisibleAddColumnBlock,
  deleteColumn,
  moveTableColumn,
  updateCommunicationTable: communicationTablesResource.operations.updateById
};

export default connect(mapStateToProps, mapDispatchToProps)(Columns);
