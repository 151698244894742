import { Button, Modal, Progress } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import ExcelIcon from '../../../images/ExcelIcon';
import { ContextExportDashboards } from '../DashboardPage';
import { exportDashboardsPages } from './operations';
import {
  checkExportStatus,
  downloadFileAfterExport
} from '../../../redux/entities/exports/operations';

const DashboardExportDrawer = ({ dashboardsByIds }) => {
  const { loading } = useSelector(state => state.dashboardAnalytics);
  const { t } = useTranslation();
  const { confirm } = Modal;
  const {
    exportWidgetDataArray,
    clearArrayExportWidget,
    handleIsExportWidget,
    isExportWidgetData
  } = useContext(ContextExportDashboards);
  const [loadingExport, setLoadingExport] = useState(false);
  const updateByIdStarted = useSelector(
    state => state.dashboardWidgetsResource.updateByIdStarted,
    isEqual
  );

  useEffect(async () => {
    if (!updateByIdStarted) {
      await clearArrayExportWidget();
      await handleIsExportWidget();
    }
  }, [updateByIdStarted]);

  useEffect(async () => {
    if (!isExportWidgetData) {
      await clearArrayExportWidget();
      await handleIsExportWidget();
    }
  }, [isExportWidgetData]);
  async function handleExportChecklistDefinitions() {
    setLoadingExport(true);
    try {
      if (!loading && !isEmpty(exportWidgetDataArray)) {
        const requestId = await exportDashboardsPages({
          exportWidgetDataArray
        }).catch(() => {
          Modal.destroyAll();
        });
        let exportIntervalId;
        let exportStatus = {};
        let exportModal;
        const exportModalTwo = () => {
          clearInterval(exportIntervalId);
          const modalInstance = confirm({
            title: t('clientInteractionsPage.exportModal.title'),
            okText: t('clientInteractionsPage.exportModal.ok'),
            cancelText: t('clientInteractionsPage.exportModal.cancelWait'),
            content: t('clientInteractionsPage.exportModal.contentBreak'),
            onOk: exportHandleOk,
            onCancel: () => exportHandleCancel(modalInstance)
          });

          return modalInstance;
        };

        const exportHandleOk = () => {
          clearInterval(exportIntervalId);
          Modal.destroyAll();
        };
        const exportHandleCancel = async modalInstance => {
          modalInstance.destroy();
          if (exportIntervalId) {
            clearInterval(exportIntervalId);
          }
          exportModal = confirm({
            confirmLoading: true,
            title: t('clientInteractionsPage.exportModal.title'),
            autoFocusButton: null,
            okButtonProps: { style: { display: 'none' } },
            cancelText: t('clientInteractionsPage.exportModal.cancel2'),
            content: <Progress percent={exportStatus.progress} status="active" />,
            onCancel: () => exportModalTwo()
          });

          exportIntervalId = setInterval(async () => {
            exportStatus = await checkExportStatus({
              body: {
                requestId
              },
              errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
            }).catch(() => {
              clearInterval(exportIntervalId);
              Modal.destroyAll();
            });

            if (exportStatus.progress === 100 || exportStatus.status === 'done') {
              clearInterval(exportIntervalId);
              exportModal.update({
                confirmLoading: false,
                content: <Progress percent={exportStatus.progress} status="success" />
              });
              await downloadFileAfterExport({
                body: {
                  requestId
                },
                errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
              }).catch(() => {
                exportModal.update({
                  content: <Progress percent={exportStatus.progress} status="exception" />
                });
                Modal.destroyAll();
              });
              Modal.destroyAll();
            } else {
              exportModal.update({
                content: <Progress percent={exportStatus.progress} status="active" />
              });
            }
          }, 2000);
        };
        exportModal = confirm({
          confirmLoading: true,
          title: t('clientInteractionsPage.exportModal.title'),
          autoFocusButton: null,
          okButtonProps: { style: { display: 'none' } },
          cancelText: t('clientInteractionsPage.exportModal.cancel2'),
          content: <Progress percent={exportStatus.progress} status="active" />,
          onCancel: () => exportModalTwo()
        });

        exportIntervalId = setInterval(async () => {
          exportStatus = await checkExportStatus({
            body: {
              requestId
            },
            errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
          }).catch(() => {
            clearInterval(exportIntervalId);
            Modal.destroyAll();
          });
          if (exportStatus.progress === 100 || exportStatus.status === 'done') {
            clearInterval(exportIntervalId);
            exportModal.update({
              confirmLoading: false,
              content: <Progress percent={exportStatus.progress} status="success" />
            });
            await downloadFileAfterExport({
              body: {
                requestId
              },
              errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
            }).catch(() => {
              exportModal.update({
                content: <Progress percent={exportStatus.progress} status="exception" />
              });
              Modal.destroyAll();
            });
            Modal.destroyAll();
          } else {
            exportModal.update({
              content: <Progress percent={exportStatus.progress} status="active" />
            });
          }
        }, 2000);
      }
    } catch (e) {
      Modal.destroyAll();
    } finally {
      setLoadingExport(false);
      await handleIsExportWidget();
    }
  }
  return (
    <>
      <OpenDrawerButton
        type="primary"
        icon={<Icon icon={ExcelIcon} />}
        onClick={handleExportChecklistDefinitions}
        disabled={loading || loadingExport}
      />
    </>
  );
};

const OpenDrawerButton = styled(Button)`
  &.ant-btn {
    position: fixed;
    top: 37%;
    right: 0;
    width: 50px !important;
    height: 50px !important;
    line-height: 3;

    z-index: 999;

    span {
      font-size: 24px !important;
    }
  }
`;

export default DashboardExportDrawer;
