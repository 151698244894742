import teamPage from './pages/teamPage';
import userAnalyticsPage from './pages/userAnalyticsPage';
import userSettingsPage from './pages/userSettingsPage';
import organizationSettingsPage from './pages/organizationSettingsPage';
import triggersSettingsPage from './pages/triggersSettingsPage';
import integrationsSettingsPage from './pages/integrationsSettingsPage';
import organizationStructurePage from './pages/organizationStructurePage';
import libraryPage from './pages/libraryPage';
import dashboardPage from './pages/dashboardPage';
import customCommunicationPage from './pages/customCommunicationPage';
import calibrationsPage from './pages/calibrationsPage';
import calibrationResultPage from './pages/calibrationResultPage';
import checklistsPage from './pages/checklistsPage';
import checklistSettingsPage from './pages/checklistSettingsPage';
import clientInteractionsPage from './pages/clientInteractionsPage';
import clientInteractionPage from './pages/clientInteractionPage';
import amoIntegrationPage from './pages/amoIntegrationPage';
import bitrixIntegrationPage from './pages/bitrixIntegrationPage';
import infoPage from './pages/infoPage';
import uploadCallsPage from './pages/uploadCallsPage';
import loginPage from './pages/loginPage';
import registerPage from './pages/registerPage';
import recoverPasswordPage from './pages/recoverPasswordPage';
import workPlanTaskConfigurationEditor from './pages/workPlanTaskConfigurationEditor';
import workPlanTasks from './pages/workPlanTasks';
import menu from './menu';
import constants from './constants';
import components from './components';
import pagesMeta from './pagesMeta';
import backgroundJob from './backgroundJob';
import errorPages from './errorPages';
import appeals from './pages/appealsPage';
import general from './general';
import templates from './templates';
import messages from './messages';
import dashboardPages from './dashboardPages';
import kpi from './pages/kpi';
import autofailScoresPage from './pages/autofailScoresPage';
import crmEntity from './pages/crmEntity';
import aiSettingsPage from './pages/aiSettingsPage';
import datasetAiPage from './pages/datasetAiPage';

export default {
  ...teamPage,
  ...userAnalyticsPage,
  ...userSettingsPage,
  ...organizationSettingsPage,
  ...integrationsSettingsPage,
  ...organizationStructurePage,
  ...triggersSettingsPage,
  ...libraryPage,
  ...dashboardPage,
  ...customCommunicationPage,
  ...calibrationsPage,
  ...calibrationResultPage,
  ...checklistsPage,
  ...checklistSettingsPage,
  ...clientInteractionsPage,
  ...clientInteractionPage,
  ...amoIntegrationPage,
  ...bitrixIntegrationPage,
  ...infoPage,
  ...uploadCallsPage,
  ...loginPage,
  ...registerPage,
  ...recoverPasswordPage,
  ...menu,
  ...constants,
  ...components,
  ...pagesMeta,
  ...backgroundJob,
  ...errorPages,
  ...workPlanTaskConfigurationEditor,
  ...workPlanTasks,
  ...appeals,
  ...templates,
  ...general,
  ...messages,
  ...dashboardPages,
  ...kpi,
  ...autofailScoresPage,
  ...crmEntity,
  ...aiSettingsPage,
  ...datasetAiPage
};
