import { Col, Empty, Row, Typography } from 'antd';
import CustomFieldValueView from 'components/CustomFieldValueView';
import { Info } from 'components/Info';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isCustomFieldVisible } from 'core/utils/isCustomFieldVisible';
import SlicedTextWithTooltip from '../../SlicedTextWithTooltip';

const { Text } = Typography;

const CustomFieldsTab = ({ customFields, customFieldsByKeys }) => {
  const { t } = useTranslation();

  if (isEmpty(customFields))
    return (
      <Row justify="center" align="middle">
        <Col>
          <Empty />
        </Col>
      </Row>
    );

  return (
    <Row>
      {Object.keys(customFields).map(
        item =>
          isCustomFieldVisible(customFieldsByKeys[item]) && (
            <Col key={item} span={24}>
              {!Object.keys(customFieldsByKeys).includes(item) && (
                <Info
                  tooltip={{
                    title: t('components.communicationInfoModal.emptyFieldDescription')
                  }}
                  iconProps={{ style: { color: 'var(--red_6)', marginRight: 4 } }}
                />
              )}
              {/* Название кастомного поля */}
              <Text strong>
                <SlicedTextWithTooltip
                  value={get(customFieldsByKeys, `${item}.name`, `${item}`)}
                  length={40}
                  template="... "
                />
              </Text>
              {': '}
              {/* Значение кастомного поля */}
              <CustomFieldValueView
                customFields={customFields}
                customFieldsByKeys={customFieldsByKeys}
                customFieldKey={item}
                maxLength={60}
              />
            </Col>
          )
      )}
    </Row>
  );
};

export default CustomFieldsTab;
