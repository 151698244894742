import { ContainerOutlined } from '@ant-design/icons';
import { Modal, Skeleton, Tabs, Tooltip } from 'antd';
import IconButton from 'components/IconButton';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { customFieldsResource } from 'redux/resources/customFields';
import { getCustomFieldsByKeys } from 'redux/selectors/customFields';
import styled from 'styled-components';
import CustomFieldsTab from './tabs/CustomFieldsTab';
import PhoneCallChain from './tabs/PhoneCallChain';
import SCard from '../Standard/SCard';

const CommunicationInfoModal = ({ id, customFields = {}, phoneCallChainId = null, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const customFieldsByKeys = useSelector(state => getCustomFieldsByKeys(state), isEqual);

  useEffect(() => {
    return () => {
      if (isEmpty(customFieldsByKeys)) {
        dispatch(customFieldsResource.operations.load({ pagination: false }));
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      setVisible(false);
    };
  }, []);

  const showModal = () => {
    setVisible(true);
  };
  const hideModal = () => setVisible(false);
  const canShowCustomFields = !isEmpty(customFields);

  return (
    <>
      <Tooltip title={t('components.recordPlayer.viewCustomFields.tooltip')}>
        <IconButton
          icon={<ContainerOutlined />}
          shape="circle-outline"
          size="small"
          onClick={showModal}
          disabled={loading}
        />
      </Tooltip>
      <Modal
        visible={visible}
        maskClosable
        destroyOnClose
        onCancel={hideModal}
        bodyStyle={{ minHeight: 240 }}
        width={780}
        okText={t('components.communicationInfoModal.ok')}
        onOk={hideModal}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {isEmpty(customFieldsByKeys) ? (
          <SCard>
            <Skeleton active />
          </SCard>
        ) : (
          <StyledTabs
            defaultActiveKey={canShowCustomFields ? 'customFields' : 'phoneCallChain'}
            tabPosition="top"
            centered
          >
            <Tabs.TabPane
              tab={t('components.communicationInfoModal.title')}
              key="customFields"
              disabled={!canShowCustomFields}
            >
              <CustomFieldsTab
                customFields={customFields}
                customFieldsByKeys={customFieldsByKeys}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t('components.communicationInfoModal.chain')}
              key="phoneCallChain"
              disabled={!phoneCallChainId}
            >
              <PhoneCallChain callId={id} phoneCallChainId={phoneCallChainId} />
            </Tabs.TabPane>
          </StyledTabs>
        )}
      </Modal>
    </>
  );
};

const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    margin-top: -4px;
  }
  & .ant-tabs-nav {
    border-bottom: 1px solid var(--gray-border);
  }
  & .ant-tabs-nav .ant-tabs-tab {
    padding: 0 16px 12px 16px;
  }
`;

export default CommunicationInfoModal;
