import React from 'react';
import { AlertCircle } from 'react-feather';
import Icon from 'components/Icon';
import { Col, Empty, Row, Spin, Tooltip, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { get, isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SCol, SRow, SText } from 'components/Standard';
import WarningIcon from 'assets/images/warning-icon.svg';
import { WidgetTitle } from 'components/WidgetTitle';
import { EmptyWidgetCard, StyledEmpty, StyledErrorMessage } from '../styled';
import SettingsModal from './SettingsModal';
import {
  customEmptyDashboardRulesWidgetTypes,
  widgetInfoTextSetter,
  widgetNameSetter
} from '../utils';
import WidgetViewLastUpdate from '../../../components/Dashboard/WidgetViewLastUpdate';

const EmptyWidget = ({ widgetData, loadingWidgetId, outdated, isWarningWidget }) => {
  const { size, type, filters, name } = widgetData;
  const { Text } = Typography;

  const { t } = useTranslation();

  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds);

  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);

  const uninstalledWidget =
    (isEmpty(widgetValue) && isEmpty(widgetData.filters)) ||
    widgetValue === 'Widget does not implemented or you make mistake in widget name!';

  const emptyWidgetData =
    (isEmpty(widgetValue) && !isEmpty(widgetData.filters)) ||
    customEmptyDashboardRulesWidgetTypes.includes(type);

  const symbolicSizeToWidth = size => {
    switch (size) {
      case 'small':
        return 4;
      case 'medium':
        return 6;
      case 'large':
        return 12;
      case 'extra_large':
        return 24;
      default:
        return 6;
    }
  };

  const setEmptyWidgetName = (type, filters, name) => {
    if (isEmpty(type)) return t('dashboardPage.widget.emptyName');
    if (isNil(name) || isEmpty(name)) {
      return widgetNameSetter({ name, type });
    }
    return name;
  };

  const outdatedWidgetTypeToDescription = {
    rating_flags_count: t('dashboardPage.widget.outdated.outdatedFlagsAndCommentsWidgets'),
    rating_flags_count_by_units: t('dashboardPage.widget.outdated.outdatedFlagsAndCommentsWidgets'),
    rating_flags_count_by_operators: t(
      'dashboardPage.widget.outdated.outdatedFlagsAndCommentsWidgets'
    )
  };

  const renderDescription = () => {
    if (isWarningWidget) {
      return (
        <SCol span={24}>
          <SText
            color="rgba(0, 0, 0, 0.45)"
            fontWeight={500}
            fontSize="24px"
            style={{ lineHeight: '24px' }}
          >
            {t('dashboardPage.widget.conflicts.title')}
          </SText>
          <SRow align="center">
            <SText maxWidth="391px" marginTop="10px" type="secondary">
              {t('dashboardPage.widget.conflicts.description1')}
            </SText>
          </SRow>
          <SRow align="center">
            <SText maxWidth="391px" type="secondary">
              {t('dashboardPage.widget.conflicts.description2')}
            </SText>
          </SRow>
        </SCol>
      );
    }
    if (outdated) {
      return (
        <>
          <StyledErrorMessage level={3} type="secondary">
            {t('dashboardPage.widget.outdated.title')}
          </StyledErrorMessage>
          <Text type="secondary">{outdatedWidgetTypeToDescription[type]}</Text>
        </>
      );
    }
    if (uninstalledWidget) {
      return (
        <>
          <StyledErrorMessage level={3} type="secondary">
            {t('dashboardPage.widget.filtersIsNotInstalled')}
          </StyledErrorMessage>
          <Text type="secondary">{t('dashboardPage.widget.needToInstallFilters')}</Text>
        </>
      );
    }
    if (emptyWidgetData) {
      return (
        <>
          <StyledErrorMessage level={3} type="secondary">
            {t('dashboardPage.widget.checkFilters')}
          </StyledErrorMessage>
          <Text type="secondary">{t('dashboardPage.widget.emptyFiltersData')}</Text>
        </>
      );
    }
  };

  return (
    <Col span={symbolicSizeToWidth(size)} key={widgetData.id} className="dddd">
      <Spin spinning={loadingWidgetId === widgetData.id} wrapperClassName="small-widget-spin">
        <EmptyWidgetCard size={size}>
          {!isWarningWidget && (
            <>
              <Row type="flex" wrap={false} alignItems="center">
                <SCol flex="auto" display="flex" alignItems="center">
                  <Tooltip title={setEmptyWidgetName(type, filters, name)}>
                    <WidgetTitle>{setEmptyWidgetName(type, filters, name)}</WidgetTitle>
                  </Tooltip>
                  {widgetInfoTextSetter({ type })}
                </SCol>
                <SCol display="flex" justifyContent="flex-end" flex="none" alignItems="center">
                  <SettingsModal widgetData={widgetData} />
                </SCol>
              </Row>
              <StyledEmpty
                image={
                  outdated ? (
                    <Icon icon={AlertCircle} color="var(--red_primary)" size="60px" />
                  ) : (
                    Empty.PRESENTED_IMAGE_SIMPLE
                  )
                }
                description={size === 'small' ? false : renderDescription()}
              />
            </>
          )}
          {isWarningWidget && (
            <>
              <Row type="flex" wrap={false} alignItems="center">
                <SCol flex="auto" display="flex" alignItems="center">
                  <Tooltip title={setEmptyWidgetName('', {}, '')}>
                    <WidgetTitle>{setEmptyWidgetName('', {}, '')}</WidgetTitle>
                  </Tooltip>
                </SCol>
                <SCol display="flex" justifyContent="flex-end" flex="none" alignItems="center">
                  <SettingsModal
                    widgetData={{ ...widgetData, name: '', type: '', filters: {} }}
                    isDisabledTypeSelectAndNameInput
                  />
                </SCol>
              </Row>
              <StyledEmpty
                image={
                  <Tooltip
                    overlayInnerStyle={{ width: '385px' }}
                    title={
                      size === 'small' ? (
                        <>
                          <SText color="white" fontWeight="bold">
                            {t('dashboardPage.widget.conflicts.title')}
                          </SText>
                          <SRow>
                            <SText color="white" width="100%">
                              {t('dashboardPage.widget.conflicts.description1')}
                            </SText>
                          </SRow>
                          <SRow>
                            <SText color="white" width="100%">
                              {t('dashboardPage.widget.conflicts.description2')}
                            </SText>
                          </SRow>
                        </>
                      ) : null
                    }
                  >
                    <img
                      src={WarningIcon}
                      alt="warning"
                      width={size === 'small' ? '43px' : '70px'}
                    />
                  </Tooltip>
                }
                description={size === 'small' ? false : renderDescription()}
              />
            </>
          )}
        </EmptyWidgetCard>
      </Spin>
    </Col>
  );
};

export default EmptyWidget;
