import { Tooltip, Typography } from 'antd';
import { getUserName } from 'components/UserPreview/getUserName';
import { RATING_CALCULATION, RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { valueFormatter } from 'core/utils/valueFormatter';
import { Trans } from 'react-i18next';
import _, { find, get, mean, orderBy, keyBy, forEach } from 'lodash';
import React from 'react';
import { stringify } from 'core/utils/queryString';
import GetUserPreview from 'components/UserPreview/GetUserPreview';
import { getFilters, valueToColorZoneColor } from '../../utils';

const { Text } = Typography;

export const prepareColumns = ({
  checklistItems,
  filters,
  checklistDefinition,
  hasGroupForWithoutGroupIds
}) => {
  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.QUESTIONS.value);
  const { viewType } = filters;
  const targetItems =
    WIDGET_VIEW_MODES.QUESTIONS.value !== viewMode
      ? checklistItems.filter(item => item.type === 'checklist-question-groups')
      : checklistItems.filter(item => item.type === 'checklist-questions');
  const mapChecklistItemsToColumns = ({ checklistItem }) => {
    return checklistItem.reduce((acc, item) => {
      const data = {
        title: (
          <Tooltip title={item.name} placement="topLeft">
            <Text>{item.name}</Text>
          </Tooltip>
        ),
        dataIndex: item.id,
        key: item.id,
        align: 'center',
        width: '200px',
        render: (text, { key }) => {
          return {
            props: {
              style: {
                background: text?.color
              }
            },
            children: (text?.value || text?.value === 0) && (
              <Text strong={key === 'footer'}>
                {viewType === WIDGET_VIEW_MODES.SCORE.value
                  ? beatifyFloat(text?.value)
                  : valueFormatter({ value: text?.value, ratingMode: viewType })}
              </Text>
            )
          };
        }
      };
      return [...acc, data];
    }, []);
  };

  // Основные колонки, которые будут всегда присутствовать
  const columns = [
    {
      title: (
        <Trans
          i18nKey={
            viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
              ? 'dashboardPage.tableChecklistItemsByOperators.table.columns.questions'
              : 'dashboardPage.tableChecklistItemsByOperators.table.columns.groups'
          }
        />
      ),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 270,
      render: (text, record) => {
        return {
          props: {
            style: {
              padding: '2px 5px'
            }
          },
          children:
            record.key === 'header' || record.key === 'footer' ? (
              <Text strong={record.key === 'footer'}>
                <Trans i18nKey={text} />
              </Text>
            ) : (
              <GetUserPreview
                userId={record.key}
                altName={record.name}
                url={`/user/${record.key}/charts${stringify({
                  filters: { ...getFilters(filters) }
                })}`}
              />
            )
        };
      }
    },
    {
      title: <Trans i18nKey="dashboardPage.widget.unitName" />,
      dataIndex: 'unitName',
      key: 'unitName',
      fixed: 'left',
      width: 80
    },

    {
      title: <Trans i18nKey="dashboardPage.dashboard.reviewsCountShort" />,
      dataIndex: 'reviewsCount',
      key: 'reviewsCount',
      fixed: 'left',
      width: 80,
      align: 'center',
      render: text => text
    },
    ...mapChecklistItemsToColumns({
      checklistItem: targetItems,
      viewMode
    }),
    {
      title: (
        <Trans i18nKey="dashboardPage.tableChecklistItemsByOperators.table.columns.totalScore" />
      ),
      dataIndex: 'totalScore',
      key: 'totalScore',
      align: 'center',
      fixed: 'right',
      width: 140,
      render: text => {
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: (
            <Text strong>
              {viewType === WIDGET_VIEW_MODES.SCORE.value
                ? beatifyFloat(text?.value)
                : valueFormatter({ value: text?.value, ratingMode: viewType })}
            </Text>
          )
        };
      }
    }
  ];

  // Добавляем колонку withoutGroups, если условия выполняются
  if (hasGroupForWithoutGroupIds && viewMode === WIDGET_VIEW_MODES.GROUPS.value) {
    columns.splice(3, 0, {
      title: <Trans i18nKey="dashboardPage.widget.withoutGroups" />,
      dataIndex: 'withoutGroups',
      key: 'withoutGroups',
      width: 80,
      align: 'center',
      render: (text, record) => {
        return {
          children: ((text !== 'N/A' && text) || text === 0) && (
            <Text strong={record.key === 'footer'}>
              {viewType === WIDGET_VIEW_MODES.SCORE.value
                ? beatifyFloat(text)
                : valueFormatter({ value: text, ratingMode: viewType })}
            </Text>
          )
        };
      }
    });
  }

  return columns;
};

export const prepareTableData = ({
  filters,
  usersByIds,
  unitsByIds,
  checklistItems,
  checklistDefinition,
  averageScoreByQuestions,
  checklistDefinitionAverageScore,
  checklistDefinitionsAverageScoresByOperators,
  checklistQuestionsAverageScoresByOperators,
  checklistQuestionGroupsAverageScoresByOperators,
  reviewsCountByOperators,
  hasGroupForWithoutGroupIds
}) => {
  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.QUESTIONS.value);
  const { viewType, viewResult } = filters;
  const widgetItem =
    viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
      ? Object.values(checklistQuestionsAverageScoresByOperators)
      : Object.values(checklistQuestionGroupsAverageScoresByOperators);

  const checklistItem =
    viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
      ? checklistItems.filter(item => item.type === 'checklist-questions')
      : checklistItems.filter(item => item.type !== 'checklist-questions');
  const checkListWithoutGroup =
    Array.isArray(checklistDefinition) &&
    checklistDefinition.filter(item => item.isGroupable === false);
  let hasCheckRatingModeColorZones = false;
  if (viewType === RATING_MODE.PERCENTAGE) {
    hasCheckRatingModeColorZones =
      Array.isArray(checklistDefinition) &&
      checklistDefinition?.every(item => item.ratingMode === viewType);
  }
  if (viewType === WIDGET_VIEW_MODES.SCORE.value) {
    hasCheckRatingModeColorZones =
      Array.isArray(checklistDefinition) &&
      checklistDefinition?.every(item => item.ratingMode === RATING_MODE.NUMBERS);
  }

  const mappedChecklistItemsScore = orderBy(
    widgetItem.reduce((acc, item) => {
      const checklistItemsByIds = keyBy(checklistItems, 'id');
      const score = get(checklistDefinitionsAverageScoresByOperators, `${item.operator_id}.value`);
      const value =
        viewResult === WIDGET_VIEW_MODES.SUM.value
          ? item.scores?.reduce(
              (total, score) => total + (score.value !== null ? beatifyFloat(score.value) : 0),
              0
            )
          : _(item?.scores)
              .filter(score => score.value !== null)
              .map(score => beatifyFloat(score.value))
              .mean();
      if (!score && score !== 0) return acc;
      const data = {
        key: item.operator_id,
        name: usersByIds[item.operator_id]
          ? getUserName({ user: usersByIds[item.operator_id] })
          : checklistDefinitionsAverageScoresByOperators[item.operator_id]?.name,
        ...item.scores.reduce((acc, item) => {
          const widgetKey =
            viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
              ? item.question_id
              : item.question_group_id;
          let colorZonesResult;
          if (viewMode === WIDGET_VIEW_MODES.QUESTIONS.value) {
            if (Array.isArray(checklistDefinition)) {
              for (let i = 0; i < checklistDefinition.length; i++) {
                if (
                  checklistDefinition[i]?.questionGroupsIds.includes(
                    checklistItemsByIds[widgetKey]?.binding?.questionGroupId
                  )
                ) {
                  colorZonesResult = checklistDefinition[i];
                }
              }
            } else {
              colorZonesResult = checklistDefinition;
            }
          } else if (Array.isArray(checklistDefinition)) {
            for (let i = 0; i < checklistDefinition.length; i++) {
              if (
                checklistDefinition[i]?.id === checklistItemsByIds[widgetKey]?.checklistDefinitionId
              ) {
                colorZonesResult = checklistDefinition[i];
              }
            }
          } else {
            colorZonesResult = checklistDefinition;
          }

          return {
            ...acc,
            [widgetKey]: {
              value: item.value,
              color: hasCheckRatingModeColorZones
                ? viewMode !== WIDGET_VIEW_MODES.QUESTIONS.value
                  ? undefined
                  : valueToColorZoneColor({
                      value: item.value,
                      colorZones:
                        colorZonesResult?.ratingMode === RATING_MODE.NUMBERS
                          ? checklistItemsByIds[widgetKey]?.colorZones
                          : colorZonesResult?.colorZones
                    })
                : 'white'
            }
          };
        }, {}),
        unitName: unitsByIds[usersByIds[item.operator_id]?.unitId]?.name,
        lastName: usersByIds[item.operator_id]?.lastName,
        totalScore: {
          value,
          color: valueToColorZoneColor({
            value,
            colorZones: 'white'
          })
        },
        reviewsCount: reviewsCountByOperators[item.operator_id]?.value
      };
      return [...acc, data];
    }, []),
    'lastName'
  );

  const checklistItemsAverageScore = () => {
    const objectKey =
      viewMode === WIDGET_VIEW_MODES.QUESTIONS.value ? 'question_id' : 'question_group_id';
    const scores = checklistItem.reduce((acc, item) => {
      const value = mean(
        _.filter(
          widgetItem.map(operator =>
            beatifyFloat(
              get(
                find(operator.scores, {
                  [objectKey]: item.id
                }),
                'value'
              )
            )),
          _.isNumber
        )
      );

      return {
        ...acc,
        [item.id]: {
          value,
          color: valueToColorZoneColor({
            value,
            colorZones:
              checklistDefinition.ratingMode === RATING_MODE.NUMBERS &&
              checklistDefinition.ratingCalculation === RATING_CALCULATION.SUM
                ? undefined
                : 'white'
          })
        }
      };
    }, {});
    return scores;
  };
  const checklistItemsAverageScoreResult = () => {
    const sumArray = mappedChecklistItemsScore.map(item => {
      return beatifyFloat(item.totalScore.value);
    });
    return _.mean(_.filter(sumArray, _.isNumber));
  };
  const averageScoreChecklistItemsWithoutGroups = [];
  const checklistItemsWithoutGroupsResult = () => {
    if (hasGroupForWithoutGroupIds) {
      const checklistWithoutGroupsIds = checkListWithoutGroup
        .map(item => item?.questionGroupsIds)
        .flat();

      let array = [];
      forEach(mappedChecklistItemsScore, item => {
        array = [];
        forEach(Object.keys(item), key => {
          if (checklistWithoutGroupsIds.includes(key)) {
            array.push(item[key]?.value);
            averageScoreChecklistItemsWithoutGroups.push(item[key]?.value);
          }
        });
        item.withoutGroups = beatifyFloat(mean(array));
      });
    }
    return mappedChecklistItemsScore;
  };

  return [
    ...checklistItemsWithoutGroupsResult(),
    {
      key: 'footer',
      name: 'dashboardPage.tableChecklistItemsByOperators.table.columns.average',
      ...checklistItemsAverageScore(),
      totalScore: {
        value: parseFloat(beatifyFloat(checklistItemsAverageScoreResult())),
        color: valueToColorZoneColor({
          value: beatifyFloat(checklistItemsAverageScoreResult()),
          colorZones: 'white'
        })
      },
      withoutGroups: beatifyFloat(mean(averageScoreChecklistItemsWithoutGroups)),
      reviewsCount: beatifyFloat(
        mean(Object.values(reviewsCountByOperators).map(item => item.value))
      )
    }
  ];
};
