import { message, Upload } from 'antd';
import Axios from 'axios';
import BraftEditor from 'bf-editor-qolio';
import 'bf-editor-qolio/dist/index.css';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { API_V1_PATH } from 'core/api';
import { apiUrl } from 'core/config';
import { processJsonApiObject } from 'core/jsonapi';
import { handleError } from 'core/services/errors';
import { isEmpty, isFunction, get, isEqual } from 'lodash';
import React, { useState } from 'react';
import { Paperclip } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { uploadedFilesResource } from 'redux/resources/uploadedFiles';
import { getCurrentUser } from 'redux/selectors/users';
import { LOCALES } from 'core/utils/constants';
import UploadedFile from '../../../UploadedFile';
import Flags from './Flags';

const TemplateEditor = ({ templateState, setTemplateState }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const preferedLocale = useSelector(state => getCurrentUser(state)['prefered-locale']);
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(templateState?.text)
  );
  const [uploadError, setUploadError] = useState(false);

  const onDeleteFile = id => {
    setTemplateState({
      ...templateState,
      uploadedFiles: get(templateState, 'uploadedFiles', []).filter(
        ({ id: fileId }) => fileId !== id
      )
    });
  };

  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);

  const uploadHandler = async ({ file, onSuccess, onError, onProgress }) => {
    // TODO: upload file here
    const headers = {
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      'content-type': 'multipart/form-data'
    };
    const uploadEndpoint = `${apiUrl}${API_V1_PATH}/organization/uploaded_files`;
    const onUploadProgress = ({ total, loaded }) => {
      const percent = Math.round((loaded * 100) / total);
      onProgress({ percent });
    };
    const body = new FormData();
    body.append('file', file);
    body.append('name', file.name);
    const hide = message.loading(t('integrationsSettingsPage.manageUserAccounts.sendingFile'), 0);
    try {
      const response = await Axios.post(uploadEndpoint, body, { headers, onUploadProgress });
      hide();
      const uploadedFile = processJsonApiObject(response.data.data);
      dispatch(uploadedFilesResource.actions.loadByIdSucceed(uploadedFile));
      setTemplateState({
        ...templateState,
        uploadedFiles: [...(templateState?.uploadedFiles || []), uploadedFile]
      });
    } catch (error) {
      console.log(error);
      hide();
      handleError(error?.response?.status, error?.response?.data);
      onError(error);
    }
  };

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 3;

    if (!isLt2M) {
      // message.error('Image must smaller than 30MB!');
      setUploadError('Image must smaller than 3MB!');
      // TODO: set error for file size
    }

    return isLt2M;
  };

  const extendControls = [
    {
      key: 'attachment',
      type: 'component',
      component: (
        <SRow display="inline-flex" width="100%">
          <SCol flex="none">
            <Upload
              showUploadList={false}
              accept="image/*"
              beforeUpload={beforeUpload}
              customRequest={uploadHandler}
            >
              <button
                type="button"
                className="control-item button upload-button"
                data-title={t('general.uploadImage')}
              >
                <Icon icon={Paperclip} />
              </button>
            </Upload>
          </SCol>
          <SCol
            flex="auto"
            display="inline-grid"
            justifyContent="flex-end"
            alignItems="flex-start"
            paddingTop="5px"
          >
            <Flags templateState={templateState} setTemplateState={setTemplateState} />
          </SCol>
        </SRow>
      )
    }
  ];

  const handleEditorChange = editorState => {
    const isEmptyText = isEmpty(editorState.toText().trim());
    setEditorState(editorState);
    if (isFunction(setTemplateState)) {
      setTemplateState({ ...templateState, text: isEmptyText ? '' : editorState.toHTML() });
    }
  };

  return (
    <SRow gutter={[0, 8]} style={{ margin: '-4px' }}>
      <SCol span={24}>
        <BraftEditor
          value={editorState}
          placeholder={t('components.commentsPanel.commentPlaceholder')}
          language={preferedLocale === LOCALES.RU.value ? LOCALES.RU.value : LOCALES.EN.value}
          controls={['bold', 'underline', 'italic', 'link', 'list-ol', 'list-ul', 'emoji']}
          stripPastedStyles
          onChange={handleEditorChange}
          draftProps={{ spellCheck: true }}
          extendControls={extendControls}
        />
      </SCol>

      <SCol span={24}>
        {templateState?.uploadedFiles?.reduce((acc, file) => {
          if (!Object.keys(uploadedFilesByIds).includes(file?.id)) return acc;
          const component = (
            <UploadedFile
              key={file.id}
              uploadedFile={file}
              allFiles={templateState?.uploadedFiles}
              onDelete={onDeleteFile}
            />
          );

          return [...acc, component];
        }, [])}
      </SCol>
    </SRow>
  );
};

export default TemplateEditor;
