import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { isEmpty, orderBy, get, isEqual } from 'lodash';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { prepareData } from './prepareFunctions';
import { WidgetCard } from '../../styled';
import { searchMaxValue } from '../../utils';
import EmptyWidget from '../../Components/EmptyWidget';

const ChecklistDefinitionAverageScoreByOperatorsHistory = ({
  widgetData,
  loadingWidgetId,
  colors,
  isWarningWidget
}) => {
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);

  let widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);
  const lastItem = Array.isArray(widgetValue) ? widgetValue[widgetValue.length - 1] : null;
  const dateLastUpdate = lastItem ? lastItem.dateLastUpdate : null;

  if (Array.isArray(widgetValue) && lastItem) {
    widgetValue = widgetValue.filter((item, idx) => idx !== widgetValue.length - 1);
  }
  const { id, type, filters, name } = widgetData;

  const widgetHistory = widgetValue.filter(item => !isEmpty(item.history));

  let preparedData = orderBy(prepareData({ widgetValue, usersByIds }), 'id', 'desc');
  preparedData = preparedData.filter(item => !item.data.every(subItem => subItem.y === 'N/A'));
  preparedData.forEach(group => {
    group.data.forEach(item => {
      if (item.y === "N/A") {
        item.y = null;
      }
    });
  });
  return (
    <>
      {isEmpty(widgetValue) ||
      isEmpty(widgetHistory) ||
      loadingWidgetId === id ||
      isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={12} key={id}>
          <WidgetCard>
            <SMLWidgetHeader
              name={name}
              type={type}
              widgetData={widgetData}
              filters={filters}
              dateLastUpdate={dateLastUpdate}
            />

            <ResponsiveLine
              data={preparedData}
              margin={{ top: 20, right: 160, bottom: 110, left: 40 }}
              xScale={{ type: 'point' }}
              yScale={{
                type: 'linear',
                max: searchMaxValue({ checklistDefinitionsByIds, filters }),
                stacked: false,
                reverse: false
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                format: d => moment(d).format('DD.MM.YY'),
                tickRotation: -90
              }}
              colors={colors}
              enableSlices="x"
              pointSize={10}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabel="y"
              pointLabelYOffset={-12}
              useMesh
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)'
                }
              ]}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

export default ChecklistDefinitionAverageScoreByOperatorsHistory;
