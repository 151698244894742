import { Col, message, Radio, Select } from 'antd';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import { CHECKLIST_DEFINITION_STATUS, DATE_PICKER_MODES } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import ExcelIcon from 'images/ExcelIcon';
import { isEmpty, isEqual, head, orderBy, intersection } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { exportTeamPage } from 'redux/entities/exports/operations';
import { getChecklistsDefinitionsByParams } from 'redux/selectors/checklists';
import { getUsersByIds } from 'redux/selectors/users';
import { changeViewType, updateFilters } from 'redux/ui/teamAnalyticsPage/reducer';
import ChoiceSelect from 'components/Inputs/ChoiceSelect';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import SRow from 'components/Standard/SRow';
import { IconButton } from 'components/Buttons';
import { getModeFromFilters } from 'components/DatePicker/utils';
import {
  prepareExportRows,
  prepareExportUserHeaderRow
} from 'pages/DashboardPages/TeamPage/Components/utils';
import { exportWidgetData } from 'pages/DashboardPage/utils';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const PageFilters = ({
  checklistDefinitionsByIds,
  units,
  filters,
  viewType,
  reviewsCountWidgets,
  showExport = false,
  widgets
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loadingExport, setLoadingExport] = useState(false);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const loading = useSelector(
    state => state.checklistDefinitionsResource.loading || state.unitsResource.loading,
    isEqual
  );
  const checklistDefinitions = useSelector(
    state =>
      getChecklistsDefinitionsByParams(state, {
        params: {
          unitsIds: [],
          statuses: [
            CHECKLIST_DEFINITION_STATUS.ARCHIVED.value,
            CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
          ],
          communicationTypes: []
        }
      }),
    isEqual
  );

  // need for export
  const reviewedUsersIds = Object.keys(reviewsCountWidgets, isEqual);
  const unitsWithReviewedUsers = orderBy(
    units.filter(unit =>
      isEmpty(filters.unitsIds)
        ? intersection(unit.usersIds, reviewedUsersIds).length > 0
        : intersection(unit.usersIds, reviewedUsersIds).length > 0 &&
          filters.unitsIds.includes(unit.id)
    ),
    'name'
  );

  const users = useSelector(state => Object.values(getUsersByIds(state)), isEqual);

  const exportAnalytics = async ({ filters }) => {
    try {
      // const widgets = await dispatch(
      //   exportTeamPage({
      //     pagination: false,
      //     filters: {
      //       ...filters,
      //       checklistDefinitionsIds:
      //         filters.checklistDefinitionsIds === 'all' ? [] : filters.checklistDefinitionsIds,
      //       clientInteractionSymbolicTimeRange:
      //         filters.clientInteractionSymbolicTimeRange === 'all'
      //           ? undefined
      //           : filters.clientInteractionSymbolicTimeRange
      //     }
      //   })
      // );

      const rows = prepareExportRows({
        usersByIds,
        viewType,
        users,
        unitsWithReviewedUsers,
        checklistDefinitionsByIds,
        widgets,
        filters,
        units
      });

      const body = {
        widgetData: {
          columns: prepareExportUserHeaderRow({ viewType }),
          rows
        }
      };

      await exportWidgetData({ body, setLoadingExport });
    } catch (error) {
      console.log(error);
      message.error(t('teamPage.pageFilters.messages.exportTableFailed'));
    }
  };

  return (
    <SCard rounded={false}>
      <SRow type="flex" justify="space-between" wrap={false}>
        <SCol flex="auto">
          <SRow type="flex" justify="start" gutter={[8, 0]}>
            <Col>
              <Radio.Group
                value={viewType}
                onChange={e => dispatch(changeViewType(e.target.value))}
              >
                <Radio.Button value="employees">{t('teamPage.buttons.employees')}</Radio.Button>
                <Radio.Button value="units">{t('teamPage.buttons.units')}</Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={5}>
              <ChoiceSelect
                placeholder={t('teamPage.pageFilters.filters.unitPlaceholder')}
                style={{ width: '100%' }}
                onChange={value => dispatch(updateFilters({ unitsIds: value }))}
                loading={loading}
                value={filters?.unitsIds}
                mode="multiple"
                allowClear
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys => {
                  if (selectedKeys.length === 1) return head(selectedKeys)?.label;
                  return `${t('general.units')}: ${selectedKeys.length}`;
                }}
                keyName="unitsIds"
                items={units}
                updateFilters={updateFilters}
                filters={filters?.unitsIds}
              >
                {units.map(unit => (
                  <Option key={unit.id} value={unit.id}>
                    {unit.name}
                  </Option>
                ))}
              </ChoiceSelect>
            </Col>
            <Col span={5}>
              <ChecklistDefinitionsSelect
                placeholder={t('general.checklistDefinitionPlaceholder')}
                checklistDefinitions={checklistDefinitions}
                loading={loading}
                onChange={checklistDefinitionsIds =>
                  dispatch(updateFilters({ checklistDefinitionsIds }))
                }
                value={
                  isEmpty(filters.checklistDefinitionsIds) ? 'all' : filters.checklistDefinitionsIds
                }
                style={{ width: '100%' }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'searchValue' })
                }
                allowAll
              />
            </Col>
            <SCol span={5}>
              <SRow>
                <CustomDatePicker
                  onChange={filters => {
                    dispatch(updateFilters({ ...filters }));
                  }}
                  value={{
                    timeFrom: filters.clientInteractionTimeFrom || filters.reviewTimeFrom,
                    timeTo: filters.clientInteractionTimeTo || filters.reviewTimeTo,
                    symbolicTimeRange:
                      filters.clientInteractionSymbolicTimeRange || filters.reviewSymbolicTimeRange
                  }}
                  mode={DATE_PICKER_MODES.default}
                  filtersMode={getModeFromFilters({ filters })}
                  position="left"
                  loading={loading}
                />
              </SRow>
            </SCol>
          </SRow>
        </SCol>
        {showExport && (
          <SCol display="flex" justifyContent="flex-end" flex="none" alignItems="center">
            <IconButton
              tooltip={{ title: t('teamPage.buttons.exportTeamPageAnalytics') }}
              button={{
                icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                marginTop: '2px',
                size: 'icon',
                onClick: () => !loadingExport && !loading && exportAnalytics({ filters })
              }}
            />
          </SCol>
        )}
      </SRow>
    </SCard>
  );
};

export default PageFilters;
