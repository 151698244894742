import api, { API_V1_PATH, API_V3_PATH } from 'core/api';
import axios from 'axios';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import { stringify } from 'core/utils/queryString';
import { apiUrl } from 'core/config';
import { createReduxResource } from 'core/redux/resources';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import decamelizeKeysDeep from 'decamelize-keys-deep';
import { isEmpty, endsWith, findIndex } from 'lodash';
import { setOperatorId } from 'redux/ui/clientInteractionPage/reducer';

export const clientInteractionsResource = createReduxResource({
  name: 'clientInteractions',
  customOperations: {
    loadMeta: {
      parseOptions: false,
      // * endpoint function that fires on operation call
      callEndpoint: async ({ options = {}, cancelTokenSource }) => {
        const getMetaUrlV3 = `${apiUrl}${API_V3_PATH}/communication_table/get${stringify(
          parseOptionsForApi(options)
        )}`;
        const getMetaUrlV1 = `${apiUrl}${API_V1_PATH}/client_interactions/meta${stringify(
          parseOptionsForApi(options)
        )}`;
        const res = await axios.get(getMetaUrlV1, {
          cancelToken: cancelTokenSource.token,
          headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('access-token'),
            client: localStorage.getItem('client'),
            uid: localStorage.getItem('uid')
          }
        });
        // const res = await axios.get(
        //   `${apiUrl}${API_V1_PATH}/client_interactions/meta${stringify(
        //     parseOptionsForApi(options)
        //   )}`,
        //   {
        //     cancelToken: cancelTokenSource.token,
        //     headers: {
        //       'Content-Type': 'application/json',
        //       'access-token': localStorage.getItem('access-token'),
        //       client: localStorage.getItem('client'),
        //       uid: localStorage.getItem('uid')
        //     }
        //   }
        // );
        return {
          ...res,
          body: {
            data: res.data
          }
        };
      },

      // * calls if request was successfull
      onSuccess: ({ result, response, dispatch }) => {
        dispatch({ type: 'uiClientInteractionPage/updateAxiosToken', payload: null });
        return camelcaseKeysDeep(response.data);
      }
    },
    updateClientInteractionOperator: {
      callEndpoint: ({ id, ...options }) => api.updateClientInteractionOperator({ id }, options),
      parseOptions: true,
      parseOptionsFunction: decamelizeKeysDeep,
      onSuccess: ({ result, dispatch, getState }) => {
        if (!result) return;

        const {
          reviewsResource,
          phoneCallsResource,
          clientInteractionsResource,
          uiClientInteractions,
          usersResource
        } = getState();

        const { reviewsIds, operatorId, id } = result;

        let reviewId = null;
        reviewsIds.forEach(item => {
          if (endsWith(window.location.pathname, `/reviews/${item}`)) reviewId = item;
        });

        dispatch({
          type: 'clientInteractions/updateByIdSucceed',
          payload: result
        });

        if (!isEmpty(reviewId))
          dispatch({
            type: 'reviews/updateByIdSucceed',
            payload: { ...reviewsResource.byIds?.[reviewId], operatorId }
          });

        dispatch({
          type: 'clientInteractionsResource/updateByIdSucceed',
          payload: { ...clientInteractionsResource.byIds[id], operatorId }
        });

        dispatch(setOperatorId(operatorId));

        const { tableRows } = uiClientInteractions;
        const index = findIndex(tableRows, { id });

        if (index !== -1)
          dispatch({
            type: 'uiClientInteractionsTable/updateTableRow',
            payload: {
              ...{
                ...tableRows[index],
                operatorId,
                ...(usersResource.byIds[operatorId] && {
                  operator: usersResource.byIds[operatorId]
                })
              }
            }
          });

        dispatch({
          type: 'phoneCalls/updateByIdSucceed',
          payload: { ...phoneCallsResource.byIds[id], operatorId }
        });

        return result;
      }
    }
  }
});
