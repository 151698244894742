import createListReducer from 'core/redux/lists/listReducer';
import { questionsResource } from 'redux/resources/questions';
import decamilize from "decamelize";

const parseSorter = sorter => {
  if (!sorter.order) return '';
  return `${sorter.order === 'descend' ? '-' : ''}${decamilize(sorter.columnKey, '_')}`;
};

const additionalReducer = {
  onQuestionCreate: (state, { payload }) => {
    const { questionId } = payload;
    return state.ids.includes(questionId) ? state : { ...state, ids: [questionId, ...state.ids] };
  },
  updateSort: (state, { payload }) => {
    return { ...state, sort: parseSorter(payload) };
  }
};

export const listParams = {
  name: 'questionsList',
  loader: questionsResource.operations.load,
  additionalReducer,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const questionsListReducer = reducer;

export { actions, questionsListReducer, operations };
