export default {
  messages: {
    success: {
      appealSended: 'Apelación enviada',
      appealCanceled: 'Apelación cancelada',
      appealDeleted: 'Apelación eliminada',
      processingCompleted: 'Procesamiento completado',
      commentSaved: 'Comentario guardado',
      commentAdded: 'Comentario añadido',
      answerSended: 'Respuesta enviada',
      templateUpdated: 'Plantilla actualizada',
      templateAdded: 'Plantilla agregada',
      templateDeleted: 'Plantilla eliminada',
      integrationCompleted: 'Integración completa',
      filtersApplied: 'Filtros aplicados',
      newTokenGenerated: 'Nuevo token generado',
      userMoved: 'El usuario se movió correctamente',
      taskStopped: 'La tarea se detiene',
      taskCompleted: 'Tarea terminada',
      configurationDeleted: 'Configuración eliminada',
      repeatingCanceled: 'Las repeticiones se detuvieron',
      settingsUpdated: 'Parámetros actualizados',
      transcriptionSent: 'Comunicación enviada a la transcripción'
    },
    warning: {
      failedTemplateUpdate: 'No se pudo actualizar la plantilla',
      failedTemplateSave: 'No se pudo guardar la plantilla',
      failedSettingsUpdate: 'No se pudieron actualizar los parámetros',
      limitExportReviews:
        'Está intentando descargar más de 5000 evaluaciones. Acortar el período para reducir el número de evaluaciones exportadas.'
    },
    error: {
      failedInstallIntegration: 'No se pudo completar la integración. Contáctenos en info@qolio.io',
      failedRegisterOrganization:
        'No se pudo registrar la organización. Vuelva a intentarlo o envíenos un correo electrónico a info@qolio.io',
      failedMoveUser: 'No se pudo mover al usuario',
      failedStopTask: 'No se pudo detener la tarea',
      failedDeleteConfiguration: 'No se pudo borrar la configuración',
      failedStopRepeating: 'No se pudo dejar de repetir',
      failedCloseTask: 'No se pudo completar la tarea'
    }
  }
};
