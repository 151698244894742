import React from 'react';
import { Col, Row, Tooltip, Typography } from 'antd';
import { get, isEmpty } from 'lodash';
import ColorZonesBar from 'components/Team/ColorZonesBar';
import UserPreview from 'components/UserPreview/UserPreview';
import DifferenceArrow from 'components/DifferenceArrow';
import RedFlag from 'components/Flags/RedFlag';
import YellowFlag from 'components/Flags/YellowFlag';
import GreenFlag from 'components/Flags/GreenFlag';
import StandardComment from 'components/Flags/StandardComment';
import { beatifyFloat } from 'core/utils/convertFloat';
import { Trans } from 'react-i18next';
import { PERMISSIONS, ANALYTICS_WIDGETS_NAMES, RATING_MODE } from 'core/utils/constants';
import { stringify } from 'core/utils/queryString';
import FlagLink from './FlagLink';
import { getFilters } from '../../../../DashboardPage/utils';

const tableColumns = ({
  currentUserPermissions = [],
  currentUserId,
  filters,
  checklistDefinitionsByIds
}) => {
  const { Text } = Typography;
  const { checklistDefinitionsIds } = filters;

  const canViewUserAnalyticsPage = userId => {
    return (
      currentUserPermissions.includes(PERMISSIONS.CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE) ||
      userId === currentUserId
    );
  };

  const selectedChecklistDefinition = get(checklistDefinitionsByIds, checklistDefinitionsIds);

  const displayTypeString =
    selectedChecklistDefinition?.ratingMode === RATING_MODE.PERCENTAGE ? '%' : '';
  // : ` ${t('teamPage.unitTable.differenceArrowScoreLiteral')}`;

  const clickBarHandler = ({ checklistDefinitionColorZone, userId, history, event }) => {
    const isNewTab = event.ctrlKey || event.metaKey;
    const link = `/user/${userId}/reviews${stringify({
      filters: {
        ...getFilters(filters),
        checklistDefinitionColorZones: checklistDefinitionColorZone
      }
    })}`;

    return isNewTab ? window.open(link) : history.push(link);
  };

  const getPrevDiff = (previousPeriod, currentPeriod) => {
    if (!isEmpty(previousPeriod)) {
      return '';
    }
    if (previousPeriod > currentPeriod) {
      const prevDiff = previousPeriod - currentPeriod;
      if (prevDiff < 0.1) return null;
      return `${beatifyFloat(prevDiff, 1)}`;
    }

    if (previousPeriod < currentPeriod) {
      const currDiff = currentPeriod - previousPeriod;
      if (currDiff < 0.1) return null;
      return `${beatifyFloat(currDiff, 1)}`;
    }
    return '';
  };

  return [
    // Сотрудник
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.columns.employee" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.employee" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      ellipsis: true,
      render: (text, record) => (
        <UserPreview
          userId={record.key}
          disabled={!canViewUserAnalyticsPage(record.key)}
          showAvatar
          margin="0px"
          link={`/user/${record.key}/charts${stringify({
            filters: {
              ...getFilters(filters)
            }
          })}`}
        />
      ),
      sorter: (a, b) => a.lastName.localeCompare(b.lastName)
    },
    // Балл
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.scoreInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.score" />
          </Text>
        </Tooltip>
      ),
      dataIndex: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS,
      key: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS,
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          <Col>
            <Text>
              {checklistDefinitionsIds === 'all' || !checklistDefinitionsIds
                ? 'N/A'
                : `${beatifyFloat(text)}${
                    get(selectedChecklistDefinition, 'ratingMode') === RATING_MODE.PERCENTAGE
                      ? '%'
                      : ''
                  }`}
            </Text>
          </Col>
          {/* {checklistDefinitionsIds === 'all' || !checklistDefinitionsIds ? null : ( */}
          {/*  <Col> */}
          {/*    <DifferenceArrow */}
          {/*      previousPeriod={record.checklist_definition_average_score_by_operators_previous} */}
          {/*      currentPeriod={text} */}
          {/*      displayTypeString={displayTypeString} */}
          {/*    /> */}
          {/*  </Col> */}
          {/* )} */}
        </Row>
      ),
      sorter: (a, b) =>
        a[ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS] -
        b[ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS]
    },
    // Балл прошлый период
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.scoreInfoPrevious" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.previous" />
          </Text>
        </Tooltip>
      ),
      dataIndex: `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}_previous`,
      key: `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}_previous`,
      align: 'center',
      width: 200,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          {checklistDefinitionsIds === 'all' || !checklistDefinitionsIds ? null : (
            <Col>
              <DifferenceArrow
                previousPeriod={text}
                currentPeriod={record.checklist_definition_average_score_by_operators}
                displayTypeString={displayTypeString}
              />
            </Col>
          )}
        </Row>
      ),
      sorter: (a, b) => {
        const averageScoreA = a?.checklist_definition_average_score_by_operators;
        const averageScorePreviousA = a?.checklist_definition_average_score_by_operators_previous;
        const averageScoreB = b?.checklist_definition_average_score_by_operators;
        const averageScorePreviousB = b?.checklist_definition_average_score_by_operators_previous;

        // Для выполнения сортировки вычисляем значение относительно прошлого периода
        const valueA = getPrevDiff(averageScorePreviousA, averageScoreA);
        const valueB = getPrevDiff(averageScorePreviousB, averageScoreB);
        return valueA - valueB;
      }
      // sorter: (a, b) =>
      //   a[`${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}_previous`] -
      //   b[`${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}_previous`]
    },
    // Проверки
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.reviewsCountInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.reviewsCount" />
          </Text>
        </Tooltip>
      ),
      dataIndex: ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
      key: ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
      align: 'center',
      width: 130,
      sorter: (a, b) =>
        a[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS] -
        b[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS]
    },
    // Отлично
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.greenZoneCountInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.greenZoneCount" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'greenZonePercentage',
      key: 'greenZonePercentage',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          <Col>
            <Text>{`${beatifyFloat(text)}%`}</Text>
          </Col>
          {/* <Col> */}
          {/*  <DifferenceArrow */}
          {/*    previousPeriod={record.greenZonePercentagePrevious} */}
          {/*    currentPeriod={text} */}
          {/*    displayTypeString="%" */}
          {/*  /> */}
          {/* </Col> */}
        </Row>
      ),
      sorter: (a, b) => a.greenZonePercentage - b.greenZonePercentage
    },
    // Отлично прошлый период
    {
      title: (
        <Tooltip
          title={<Trans i18nKey="teamPage.unitTable.infoIcons.greenZoneCountInfoPrevious" />}
        >
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.previous" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'greenZonePercentagePrevious',
      key: 'greenZonePercentagePrevious',
      align: 'center',
      width: 200,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          <Col>
            <DifferenceArrow
              previousPeriod={text}
              currentPeriod={record.greenZonePercentage}
              displayTypeString="%"
            />
          </Col>
        </Row>
      ),
      sorter: (a, b) => {
        const greenZonePercentageA = a?.greenZonePercentage;
        const greenZonePercentagePreviousA = a?.greenZonePercentagePrevious;
        const greenZonePercentageB = b?.greenZonePercentage;
        const greenZonePercentagePreviousB = b?.greenZonePercentagePrevious;
        // Для выполнения сортировки вычисляем значение относительно прошлого периода
        const valueA = getPrevDiff(greenZonePercentagePreviousA, greenZonePercentageA);
        const valueB = getPrevDiff(greenZonePercentagePreviousB, greenZonePercentageB);
        return valueA - valueB;
      }
      // sorter: (a, b) => a.greenZonePercentagePrevious - b.greenZonePercentagePrevious
    },
    // Все оценки
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.allScoresInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.allScores" />
          </Text>
        </Tooltip>
      ),
      key: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
      dataIndex: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
      width: 200,
      render: (text, record) => (
        <ColorZonesBar
          data={text}
          reviewsCount={record[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS]}
          onClickHandler={data =>
            clickBarHandler({
              checklistDefinitionColorZone: data.nodeEvent.id,
              userId: record.key,
              history: data.history,
              event: data.e
            })
          }
        />
      ),
      align: 'center'
    },
    // Без флагов
    {
      title: <StandardComment />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: (text, record) => (
        <FlagLink color="empty" filters={filters} userId={record?.key}>
          <Row gutter={[4, 4]} type="flex" justify="center">
            <Col>
              <StandardComment />
            </Col>
            <Col>
              <Text>{`${get(text, 'without_flag', 0)}`}</Text>
            </Col>
          </Row>
        </FlagLink>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`, 0)
    },
    // Красный флаг
    {
      title: <RedFlag />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: (text, record) => (
        <FlagLink color="red" filters={filters} userId={record?.key}>
          <Row gutter={[4, 4]} type="flex" justify="center" style={{ padding: '7px 0px' }}>
            <Col>
              <RedFlag />
            </Col>
            <Col>
              <Text>{`${get(text, 'red', 0)}`}</Text>
            </Col>
          </Row>
        </FlagLink>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`, 0)
    },
    // Жёлтый флаг
    {
      title: <YellowFlag />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: (text, record) => (
        <FlagLink color="yellow" filters={filters} userId={record?.key}>
          <Row gutter={[4, 4]} type="flex" justify="center">
            <Col>
              <YellowFlag />
            </Col>
            <Col>
              <Text>{`${get(text, 'yellow', 0)}`}</Text>
            </Col>
          </Row>
        </FlagLink>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`, 0)
    },
    // Зелённый флаг
    {
      title: <GreenFlag />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: (text, record) => (
        <FlagLink color="green" filters={filters} userId={record?.key}>
          <Row gutter={[4, 4]} type="flex" justify="center">
            <Col>
              <GreenFlag />
            </Col>
            <Col>
              <Text>{`${get(text, 'green', 0)}`}</Text>
            </Col>
          </Row>
        </FlagLink>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`, 0)
    }
  ];
};

export default tableColumns;
