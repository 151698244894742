import { bitrixClientInteractionsListReducer } from 'redux/lists/bitrixClientInteractionsList';
import { bitrixReviewsListReducer } from 'redux/lists/bitrixReviewsListReducer';
import { calibrationSessionsListReducer } from 'redux/lists/calibrationSessionsListReducer';
import { callsListReducer } from 'redux/lists/callsListReducer';
import { checklistDefinitionsListReducer } from 'redux/lists/checklistDefinitionsList';
import { clientInteractionsListReducer } from 'redux/lists/clientInteractionsList';
import { foldersListReducer } from 'redux/lists/foldersListReducer';
import { questionsListReducer } from 'redux/lists/questionsList';
import { reviewsListReducer } from 'redux/lists/reviewsListReducer';
import { getTasksListReducers } from 'redux/lists/tasksLists';
import { uploadedCallsReducer } from 'redux/lists/uploadedCallsListReducer';
import { userReviewsListReducer } from 'redux/lists/userReviewsListReducer';
import { userReviewsWithCommentsListReducer } from 'redux/lists/userReviewsWithComments';
import { usersListReducer } from 'redux/lists/usersListReducer';
import { taskDefinitionsListReducer } from 'redux/lists/taskDefinitionsListReducer';
import { usersToUnitListReducer } from 'redux/lists/usersToUnitList';
import { userAccountBindingsListReducer } from 'redux/lists/userAccountBindingsList';
import { workPlanTaskConfigurationsListReducer } from 'redux/lists/workPlanTaskConfigurationsListReducer';
import { workPlanTasksListReducer } from 'redux/lists/workPlanTasksListReducer';
import { workPlanTaskAssignmentsListReducer } from 'redux/lists/workPlanTaskAssignmentsListReducer';
import { commentTemplatesListReducer } from 'redux/lists/commentTemplatesListReducer';
import { appellationsListReducer } from 'redux/lists/appellationsListReducer';
import { unitsListReducer } from 'redux/lists/unitsListReducer';
import { promptsListReducer } from 'redux/lists/promptsList';
import { promptsQuestionsListReducer } from 'redux/lists/promptsQuestionsList';
import { promptsCustomFieldsReducer } from 'redux/lists/promptsCustomFieldsList';
import { promptsQuestionsAllListReducer } from 'redux/lists/promptsQuestionsAllList';
import { promptsCustomFieldsAllListReducer } from 'redux/lists/promptsCustomFieldsAllList';
import { aiSettingsListReducer } from '../lists/aiSettingsList';
import { datasetsListReducer } from 'redux/lists/datasetList';
import { datasetsFileListReducer } from 'redux/lists/datasetFileList';
import { datasetsBindingReducer } from 'redux/lists/datasetBinding';
import { uploadedCallsReducerV3 } from 'redux/lists/uploadedCallsListReducerV3';
import { callsListReducerV3 } from 'redux/lists/callsListReducerV3';

export default {
  reviewsList: reviewsListReducer,
  userReviewsList: userReviewsListReducer,
  userReviewsWithCommentsList: userReviewsWithCommentsListReducer,
  callsList: callsListReducer,
  uploadedCallsList: uploadedCallsReducer,
  usersList: usersListReducer,
  bitrixReviewsList: bitrixReviewsListReducer,
  bitrixClientInteractionsList: bitrixClientInteractionsListReducer,
  clientInteractionsList: clientInteractionsListReducer,
  checklistDefinitionsList: checklistDefinitionsListReducer,
  promptsList: promptsListReducer,
  questionsList: questionsListReducer,
  promptsQuestionsList: promptsQuestionsListReducer,
  promptsQuestionAllList: promptsQuestionsAllListReducer,
  promptsCustomFieldsAllList: promptsCustomFieldsAllListReducer,
  promptsCustomFieldsList: promptsCustomFieldsReducer,
  calibrationSessionsList: calibrationSessionsListReducer,
  foldersList: foldersListReducer,
  taskDefinitionsList: taskDefinitionsListReducer,
  usersToUnitList: usersToUnitListReducer,
  userAccountBindingsList: userAccountBindingsListReducer,
  workPlanTaskConfigurationsList: workPlanTaskConfigurationsListReducer,
  workPlanTasksList: workPlanTasksListReducer,
  workPlanTaskAssignmentsList: workPlanTaskAssignmentsListReducer,
  commentTemplatesList: commentTemplatesListReducer,
  appellationsList: appellationsListReducer,
  unitsList: unitsListReducer,
  aiSettingsList: aiSettingsListReducer,
  datasetsList: datasetsListReducer,
  datasetsFileList: datasetsFileListReducer,
  datasetsBinding: datasetsBindingReducer,
  callsListV3: callsListReducerV3,
  uploadedCallsListV3: uploadedCallsReducerV3,
  ...getTasksListReducers()
};
