import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Typography } from 'antd';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import RegisterFromIntegration from 'components/RegisterFromIntegration';
import SCard from 'components/Standard/SCard';
import AmoService from 'core/services/amocrm';
import { AMO_COMMANDS } from 'core/utils/constants';
import BackgroundJob from 'pages/BackgroundJob';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { registerWithAmo, syncDataWithAmo } from 'redux/entities/amoIntegration/operations';
import { saveBackgroundJob } from 'redux/entities/backgroundJob/operations';
import { setShowBJModal, setShowUsersModal } from 'redux/ui/registerFromIntegration/reducer';
import { AuthLogo } from '../styled';

const { Title } = Typography;

const RegisterPage = ({
  syncDataWithAmo,
  registerWithAmo,
  showUsersModal,
  setShowUsersModal,
  showBJModal,
  setShowBJModal,
  history
}) => {
  // FIXME : move form to wrapper
  const [user, setUser] = useState('');
  const [organization, setOrganization] = useState('');
  const [domain, setDomain] = useState('dealapp.amocrm.ru');

  useEffect(() => {
    AmoService.initMessageListener({
      command: AMO_COMMANDS.SEND_AMO_SETTINGS,
      callback: ({ user, domain, subdomain }) => {
        setUser(user);
        setDomain(domain);
        setOrganization(subdomain);
      }
    });

    AmoService.sendMessage({ command: AMO_COMMANDS.ASK_AMO_SETTINGS });
  }, []);

  const register = async params => {
    await registerWithAmo({
      ...params,
      domain,
      onSuccess: integration => history.push(`/setup-organization/${integration.id}`)
          });
  };

  const onBackgroundJobDone = () => {
    setShowBJModal(false);
    setShowUsersModal(true);
  };

  const onFinishRegister = () => {
    message.info('Вы можете пользоваться приложением');
    setShowUsersModal(false);
    history.push('/');
  };

  const onBackgroundJobFailed = () => {
    Modal.error({
      title: 'Возникла ошибка при синхронизации данных с AmoCRM',
      content: 'Пожалуйста, переустановите приложение или напишите нам на info@qolio.io',
      onOk: () => {
        setShowBJModal(false);
        localStorage.clear();
      }
    });
  };

  const RegisterForm = ({ register }) => {
    const [form] = Form.useForm();

    return (
      <Form
        form={form}
        name="register"
        onFinish={register}
        initialValues={{ email: user, organization, agreement: false }}
        scrollToFirstError
      >
        {/* <span>{`Приветствуем, ${user.NAME || ''}`}</span> */}
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Неправильный email'
            },
            {
              required: true,
              message: 'Введите email'
            }
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Введите Имя'
            }
          ]}
        >
          <Input placeholder="Имя" />
        </Form.Item>

        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Введите Фамилию'
            }
          ]}
        >
          <Input placeholder="Фамилия" />
        </Form.Item>

        <Form.Item
          name="organizationName"
          rules={[
            {
              required: true,
              message: 'Введите название организации'
            }
          ]}
        >
          <Input placeholder="Название организации" />
        </Form.Item>

        <Form.Item
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Введите пароль'
            },
            { min: 6, message: 'Минимум 6 символов' }
          ]}
        >
          <Input.Password placeholder="Пароль" />
        </Form.Item>

        <Form.Item
          name="confirm"
          hasFeedback
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Введите пароль ещё раз'
            },
            { min: 6, message: 'Минимум 6 символов' },
            {
              validator: (rule, value) => {
                if (!value || form.getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject('Пароли не совпадают');
              }
            }
          ]}
        >
          <Input.Password placeholder="Повторите пароль" />
        </Form.Item>

        <Form.Item
          name="agreement"
          rules={[
            {
              required: true,
              transform: value => value || undefined,
              type: 'boolean',
              message: 'Для регистрации необходимо ознакомиться с соглашением'
            }
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            {`Я согласен(на) с `}
            <a target="_blank" rel="noopener noreferrer" href="https://qolio.ru/offer">
              договором публичной оферты
            </a>
            {` и `}
            <a target="_blank" rel="noopener noreferrer" href="https://qolio.ru/privacy_policy">
              политикой конфиденциальности
            </a>
          </Checkbox>
        </Form.Item>

        <Button type="primary" style={{ width: '100%' }} htmlType="submit">
          Зарегистрировать Организацию из AmoCRM
        </Button>
      </Form>
    );
  };

  return (
    <Col span={24}>
      <AuthLogo />
      <Row type="flex" align="middle" justify="center" style={{ height: '100%' }}>
        <Col {...{ sm: { span: 12 }, md: { span: 10 }, xl: { span: 8 }, xxl: { span: 6 } }}>
          <SCard shadowed>
            <Row justify="space-between" type="flex" align="middle">
              <Col>
                <Title level={3}>Регистрация c AmoCRM</Title>
              </Col>
              <Col>
                <Link to="/login">Войти</Link>
              </Col>
            </Row>
            <RegisterForm register={register} />
          </SCard>

          <RegisterFromIntegration
            visible={showUsersModal}
            onProceed={onFinishRegister}
            fetchIntegrationData={syncDataWithAmo}
          />
          <Modal
            visible={showBJModal}
            footer={null}
            closable={false}
            maskClosable
            // maskClosable={false}
          >
            <BackgroundJob
              text={
                <video autoPlay loop muted playsinline>
                  <source src={catWithSpinner} type="video/mp4" />
                </video>
              }
              spinning={false}
              onDone={onBackgroundJobDone}
              onFailed={onBackgroundJobFailed}
            />
          </Modal>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  currentBackgroundJob: state.backgroundJob.currentBackgroundJob,
  showBJModal: state.uiRegisterFromIntegration.showBJModal,
  showUsersModal: state.uiRegisterFromIntegration.showUsersModal
});

const mapDispatchToProps = {
  registerWithAmo,
  saveBackgroundJob,
  setShowBJModal,
  syncDataWithAmo,
  setShowUsersModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPage));
