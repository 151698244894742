import React, { useCallback } from 'react';
import { Col, Row, Select, Tooltip } from 'antd';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import useEvent from '@react-hook/event';
import { isEqual, pick } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconButton from '../IconButton';
import { PlaybackRateSelect } from '../PhoneCallPlayer/RecordPlayer/styled';
import DownloadButton from '../PhoneCallPlayer/RecordPlayer/DownloadButton';
import { togglePlaying, updatePlayerState } from '../../redux/ui/recordPlayer/reducer';

const AudioControls = ({ recordUrl }) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const { isPlaying, playedSeconds, playbackRate } = useSelector(
    state => pick(state.uiRecordPlayer, ['isPlaying', 'playedSeconds', 'playbackRate']),
    isEqual
  );
  const dispatch = useDispatch();
  const changePlaybackRate = useCallback(
    rate => dispatch(updatePlayerState({ playbackRate: rate })),
    [dispatch]
  );
  const handleTogglePlaying = useCallback(() => dispatch(togglePlaying()), [dispatch]);

  const handleKeyboardPress = e => {
    const isEditorKeyPress = e => {
      return (
        e.target.classList.contains('public-DraftEditor-content') ||
        e.target.classList.contains('ant-input') ||
        e.target.classList.contains('ant-select-selection-search-input')
      );
    };

    const keysToEvents = {
      Space: e => {
        e.preventDefault();
        handleTogglePlaying();
      },
      ArrowUp: () => {
        e.preventDefault();
        changePlaybackRate(playbackRate < 2 ? playbackRate + 0.25 : 2);
      },
      ArrowDown: () => {
        e.preventDefault();
        changePlaybackRate(playbackRate > 0.5 ? playbackRate - 0.25 : 0.5);
      }
    };

    if (!Object.keys(keysToEvents).includes(e.code)) return;
    if (e.code === 'Space' && e.shiftKey) return;

    return isEditorKeyPress(e) ? null : keysToEvents[e.code](e);
  };

  useEvent(document, 'keydown', handleKeyboardPress);

  return (
    <Row type="flex" align="middle" justify="space-between">
      <Col>
        <Row align="middle" justify="space-between">
          <Col>
            <Tooltip title={t('components.recordPlayer.recordControls.play')}>
              <IconButton
                icon={
                  isPlaying ? (
                    <PauseOutlined style={{ fontSize: 16 }} />
                  ) : (
                    <CaretRightOutlined style={{ fontSize: 16 }} />
                  )
                }
                shape="circle-outline"
                size="small"
                onClick={() => {
                  handleTogglePlaying();
                }}
                margin="0 8px"
              />
            </Tooltip>
            <PlaybackRateSelect value={playbackRate || 1.0} onChange={changePlaybackRate}>
              <Option value={0.5}>0.50</Option>
              <Option value={0.75}>0.75</Option>
              <Option value={1.0}>1.00</Option>
              <Option value={1.25}>1.25</Option>
              <Option value={1.5}>1.50</Option>
              <Option value={1.75}>1.75</Option>
              <Option value={2.0}>2.00</Option>
            </PlaybackRateSelect>
          </Col>
          <Col style={{ marginLeft: '5px' }}>
            <Row type="flex" align="middle" justify="start" gutter={[6, 0]}>
              <Col>
                <DownloadButton recordUrl={recordUrl} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AudioControls;
