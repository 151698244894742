import React from 'react';
import { utils } from 'react-media-player';
import { shallowEqual, useSelector } from 'react-redux';
import { pick } from 'lodash';
import SText from 'components/Standard/SText';
import {
  BufferedStatus,
  PlayerDivider,
  PlayerRow,
  PlayerTrack
} from '../PhoneCallPlayer/RecordPlayer/styled';

const AudioTrack = ({ bufferedPercentage }) => {
  const { duration, playedSeconds } = useSelector(
    state => pick(state.uiRecordPlayer, ['playedSeconds', 'duration']),
    shallowEqual
  );

  const pointerRelativePosition = (playedSeconds / duration) * 100;

  return (
    <PlayerRow type="flex" justify="space-around" align="middle" wrap={false}>
      <SText flex="none" style={{ marginRight: '10px' }}>
        {utils.formatTime(playedSeconds)}
      </SText>
      <PlayerTrack
        xs={16}
        sm={18}
        md={19}
        lg={20}
        xl={21}
        xxl={21}
        id="row"
        style={{ width: '300px' }}
        className="player-track"
      >
        <PlayerDivider left={`${pointerRelativePosition}%`} />
        <BufferedStatus id="buffered" bufferedPercentage={bufferedPercentage} />
      </PlayerTrack>
      <SText flex="none" style={{ marginLeft: '10px' }}>
        {utils.formatTime(duration)}
      </SText>
    </PlayerRow>
  );
};

export default AudioTrack;
