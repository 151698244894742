import {
  AppstoreOutlined,
  BookOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  SnippetsOutlined,
  TableOutlined,
  TagOutlined,
  ExceptionOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Shuffle } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Icon from 'components/Icon';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/users';
import { get, isEqual, orderBy } from 'lodash';
import { ReactComponent as SupportIcon } from 'assets/images/supportIcon.svg';
import WorkPlanTasksMenu from './WorkPlanTasksMenu';

import {
  CalibrationsMenuItem,
  ChecklistMenuItem,
  ClientInterationsMenuItem,
  DashboardMenuItem,
  LibraryMenuItem,
  RightSideItemsDivider,
  SettingsSubMenu,
  TaskDefinitionsMenuItem,
  TeamMenuItem,
  AppealsMenuItem,
  WorkPlanTaskConfigurationsMenuItem,
  UnitsAnalyticsMenuItem,
  UnitsByTypesAnalyticsMenuItem,
  // AutofailAnalyticsMenuItem,
  AppealsAnalyticsMenuItem,
  AnalyticsSubMenu,
  SupportMenuItem, UploadCallsPageMenuItem
} from './Components/MenuItems';
import Chatra from '@chatra/chatra';
import { loadOrganization } from 'redux/entities/organization/operations';

const StyledLink = styled(Link)`
  && {
    display: flex !important;
    align-items: center;
  }
`;

const AppMenu = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadOrganization());
  }, []);
  const currentUserAttributes = useSelector(state => state.reduxTokenAuth.currentUser.attributes);
  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);
  const integrations = useSelector(
    state => orderBy(Object.values(state.integrationsResource.byIds), 'createdAt', 'desc'),
    isEqual
  );

  const getChatraIntegrationData = () => {
    let integrationsString = '';
    if (!!integrations && !!integrations.length) {
      integrationsString = integrations.reduce(
        (accumulator, currentValue) => {
            return accumulator + currentValue.name + ', '
        }
      , '');
      integrationsString = integrationsString.slice(0, -2);
    }    
    return {
      id: currentUserAttributes?.id,
      organization_id: currentUserAttributes?.user['organization-id'],
      organization_name: currentUserAttributes?.user?.organization.name,
      email: currentUserAttributes.user?.email,
      name: currentUserAttributes?.user['first-name'] + ' ' + currentUserAttributes.user['last-name'],
      photo: currentUserAttributes?.user['avatar-url'],
      phone: organizationInfo?.phoneNumber,
      planId: organizationInfo?.planId,
      planSlug: organizationInfo?.planSlug,
      activeUsersCount: organizationInfo?.activeUsersCount,
      organization_email: organizationInfo?.email,
      integrations: integrationsString,
    };
  }
  let config = {
    ID: 'Aic5CKduGFKvzR9Ph',
    setup: {
      buttonSize: 0,
      customWidgetButton: '.custom-chat-button'
    },
    integration: getChatraIntegrationData(),
  }
  
  Chatra('init', config);
  // eslint-disable-next-line no-undef
  const handleSupportClick = () => {
    if (window?.Chatra ) {
      if (!window.Chatra._chatExpanded) {
        window.ChatraSetup = {
          clientId: currentUserAttributes.user?.email,
          customWidgetButton: '.custom-chat-button'
        };
        Chatra('restart');
        window.Chatra.openChat();
      } else {
        window.Chatra.closeChat()
      }
    }
  };
  const currentUser = useSelector(getCurrentUser, isEqual);
  const allowViewAnalytics = get(
    currentUser,
    'organization.settings.analytics.scores-by-units-by-communication-type-enabled',
    false
  );

  // TODO: ПМ который закрывается по наличию сеттинга, нужно сделать универсальнее в файле MenuItems.

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      style={{ height: '48px', display: 'flex', alignItems: 'center' }}
      selectedKeys={[location.pathname]}
    >
      <DashboardMenuItem key="/dashboards">
        <StyledLink to="/dashboards">
          <DashboardOutlined />
          <span>{t('menu.appMenu.analytics')}</span>
        </StyledLink>
      </DashboardMenuItem>
      <AnalyticsSubMenu
        key="/analytics"
        title={
          <>
            <Icon icon={PieChart} />
            <span>{t('menu.appMenu.reports')}</span>
          </>
        }
      >
        <UnitsAnalyticsMenuItem key="/units-analytics">
          <StyledLink to="/units-analytics">
            <span>{t('menu.appMenu.dynamicsOfEvaluationsByUnits')}</span>
          </StyledLink>
        </UnitsAnalyticsMenuItem>

        {allowViewAnalytics && (
          <UnitsByTypesAnalyticsMenuItem key="/units-by-type-analytics">
            <StyledLink to="/units-by-type-analytics">
              <span>Динамика оценок по отделам по типам коммуникаций</span>
            </StyledLink>
          </UnitsByTypesAnalyticsMenuItem>
        )}

        <AppealsAnalyticsMenuItem key="/appeals-analytics">
          <StyledLink to="/appeals-analytics">
            <span>{t('menu.appMenu.appeals')}</span>
          </StyledLink>
        </AppealsAnalyticsMenuItem>

        <TeamMenuItem key="/team">
          <StyledLink to="/team">
            <span>{t('menu.appMenu.team')}</span>
          </StyledLink>
        </TeamMenuItem>

        {/* <AutofailAnalyticsMenuItem key="/autofail-scores">
          <StyledLink to="/autofail-scores">
            <span>{t('menu.appMenu.autoFailScores')}</span>
          </StyledLink>
        </AutofailAnalyticsMenuItem> */}
      </AnalyticsSubMenu>

      <ClientInterationsMenuItem key="/client-interactions">
        <StyledLink to="/client-interactions">
          <TableOutlined />
          <span>{t('menu.appMenu.communications')}</span>
        </StyledLink>
      </ClientInterationsMenuItem>

      <AppealsMenuItem key="/appeals">
        <StyledLink to="/appeals">
          <ExceptionOutlined />
          <span>{t('menu.appMenu.appeals')}</span>
        </StyledLink>
      </AppealsMenuItem>

      <LibraryMenuItem key="/library">
        <StyledLink to="/library">
          <BookOutlined />
          <span>{t('menu.appMenu.library')}</span>
        </StyledLink>
      </LibraryMenuItem>

      <RightSideItemsDivider />

      <WorkPlanTasksMenu />

      <SettingsSubMenu
        key="settings"
        title={
          <>
            <AppstoreOutlined />
            <span>{t('menu.appMenu.settings')}</span>
          </>
        }
      >
        <ChecklistMenuItem key="/checklists">
          <StyledLink to="/checklists">
            <FileDoneOutlined />
            <span>{t('menu.appMenu.checklistsSettings')}</span>
          </StyledLink>
        </ChecklistMenuItem>

        <TaskDefinitionsMenuItem key="/settings/triggers">
          <StyledLink to="/settings/triggers">
            <TagOutlined />
            <span>{t('menu.appMenu.tagsSettings')}</span>
          </StyledLink>
        </TaskDefinitionsMenuItem>

        <CalibrationsMenuItem key="/calibrations">
          <StyledLink to="/calibrations">
            <SnippetsOutlined />
            <span>{t('menu.appMenu.calibrations')}</span>
          </StyledLink>
        </CalibrationsMenuItem>

        <WorkPlanTaskConfigurationsMenuItem key="/work-plan/task-configurations">
          <StyledLink to="/work-plan/task-configurations">
            <Icon icon={Shuffle} />
            <span>{t('menu.appMenu.workPlanTaskConfigurations')}</span>
          </StyledLink>
        </WorkPlanTaskConfigurationsMenuItem>

        <UploadCallsPageMenuItem key="/upload">
          <StyledLink to="/upload">
            <DownloadOutlined />
            <span>{t('menu.appMenu.upload')}</span>
          </StyledLink>
        </UploadCallsPageMenuItem>
      </SettingsSubMenu>

      <SupportMenuItem onClick={handleSupportClick} style={{ paddingLeft: 0 }} key="support">
        <Icon icon={SupportIcon} width="18px" height="18px" />
      </SupportMenuItem>
    </Menu>
  );
};

export default AppMenu;
