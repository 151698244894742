import { Col, Row, Spin, Typography } from 'antd';
import { audioServiceOrigin } from 'core/config';
import qs from 'qs';
import nodePath from 'path';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Player from 'react-player';
import api, { downloadCallFromMediaUrl } from 'core/api';
import { useHistory } from 'react-router-dom';
import {
  setCurrentCallId,
  setUpdatingUrl,
  setUrl,
  updatePlayerState
} from 'redux/ui/recordPlayer/reducer';
import openSocket from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual, pick } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import truncateString from 'core/utils/truncateString';
import SModal from 'components/Standard/SModal';
import AudioTrack from './AudioTrack';
import RecordControls from './RecordControls';
import { getCredentialsNaumen } from '../../../redux/entities/naumenIntegration/operations';

const { Text } = Typography;

const RecordPlayer = ({
  messages,
  onMessageClick,
  review,
  call,
  comments,
  allowAttachTags = true,
  showTags = true,
  shouldLoad = true,
  fromLibrary = false,
  fromNewReview = false
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const player = useRef();
  const [bufferedPercentage, setBufferedPercentage] = useState(0);
  const [auth, setAuth] = useState(null);
  const [loadAudio, setLoadAudio] = useState(false);
  const [loadDuration, setLoadDuration] = useState(true);
  const [startGetMedia, setStartGetMedia] = useState(true);
  const [prevUrl, setPrevUrl] = useState(null);
  const [isRetry, setIsRetry] = useState(false);

  const {
    playbackRate,
    isPlaying,
    wasPlayed,
    forcePlayed,
    playedSeconds,
    url,
    updatingUrl
  } = useSelector(
    state =>
      pick(state.uiRecordPlayer, [
        'playbackRate',
        'isPlaying',
        'wasPlayed',
        'forcePlayed',
        'playedSeconds',
        'url',
        'updatingUrl'
      ]),
    isEqual
  );

  const { mediaUrl, phoneCallMediaUrl } = call;
  // const phoneCallMediaUrl = call?.metadata?.mediaUrls[0];

  const getPhoneCallMediaUrlGetMedia = () => {
    // Если это коммуникация созданая с помощью (Новая проверка)
    if (fromNewReview && call?.metadata?.mediaUrls?.length > 0) {
      return call?.metadata?.mediaUrls[0];
    }
    if (!!phoneCallMediaUrl) {
      return phoneCallMediaUrl;
    }
    return null;
  };

  const [phoneCallMediaUrlGetMedia, setPhoneCallMediaUrlGetMedia] = useState(
    getPhoneCallMediaUrlGetMedia()
  );
  // const [phoneCallMediaUrlGetMedia, setPhoneCallMediaUrlGetMedia] = useState(!!phoneCallMediaUrl ? phoneCallMediaUrl : null);

  async function getMediaUrl(id) {
    try {
      const response = await api.getMediaUrlV3({ id });
      if (response && response.body && response.body.media_url) {
        setPhoneCallMediaUrlGetMedia(response.body.media_url);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  useEffect(() => {
    if (fromLibrary) {
      getMediaUrl(call.id);
    }
  }, [call.id, setPhoneCallMediaUrlGetMedia]);

  const unsafeRecordUrl = (phoneCallMediaUrlGetMedia ?? phoneCallMediaUrl) || mediaUrl;

  const setTime = useCallback(
    seconds => {
      if (player.current && player.current.seekTo) {
        player.current.seekTo(seconds, 'seconds');
      }
    },
    [player?.current]
  );

  useEffect(() => {
    if (call) dispatch(setCurrentCallId(call.id));
    if (call.duration) dispatch(updatePlayerState({ duration: call.duration }));
  }, [call.id, call.duration, loadAudio]);
  const isVideo = url => {
    const videoFormats = ['.mp4', '.mov', '.avi', '.mkv', '.flv', '.wmv'];
    const videoDomains = [
      'youtube.com',
      'youtu.be',
      'vimeo.com',
      'dailymotion.com',
      'twitch.tv',
      'metacafe.com',
      'veoh.com',
      'video.google.com',
      'hulu.com',
      'netflix.com',
      'disneyplus.com',
      'hbomax.com',
      'peacocktv.com',
      'paramountplus.com',
      'appletv.apple.com',
      'primevideo.com'
    ];

    return (
      url &&
      (videoFormats.some(format => url.includes(format)) ||
        videoDomains.some(domain => url.includes(domain)))
    );
  };

  const fetchMediaData = async requestUrl => {
    try {
      if (isEmpty(auth) && isEmpty(url) && isEmpty(unsafeRecordUrl)) {
        return SModal.warning({
          title: 'Не удается воспроизвести запись',
          closable: true,
          okText: 'OK',
          onOk: () => {
            dispatch(setUpdatingUrl(true));
            setStartGetMedia(true);
            setLoadAudio(false);
          },
          content: (
            <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
              <Col span={24}>
                <Text>
                  Не удалось воспроизвести запись. Перейдите по следующей ссылке, если звонок не
                  воспроизводится: <b>проверьте настройки</b> телефонии.
                </Text>
              </Col>
              <Col span={24}>
                <Text
                  copyable={{
                    text: unsafeRecordUrl,
                    tooltips: [
                      t('constants.errors.loadingRecordError.tooltip.copy'),
                      t('constants.errors.loadingRecordError.tooltip.copied')
                    ]
                  }}
                  className="truncated"
                >
                  <a href={unsafeRecordUrl} target="_blank">
                    {truncateString(unsafeRecordUrl, 40)}
                  </a>
                </Text>
              </Col>
            </Row>
          )
        });
      }

      let credentials;
      const mediaUrl = requestUrl || url || unsafeRecordUrl;
      if (!isVideo(url || unsafeRecordUrl)) {
        if (mediaUrl && mediaUrl.includes('.nau')) {
          credentials = await getCredentialsNaumen(call?.id);
        }

        const headers = !isEmpty(credentials)
          ? { Authorization: `Basic ${btoa(`${credentials?.username}:${credentials?.password}`)}` }
          : {};

        const response = await fetch(downloadCallFromMediaUrl, {
          method: 'POST',
          body: JSON.stringify({ media_url: mediaUrl }),
          headers
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        await setAuth(URL.createObjectURL(blob));
      } else {
        setAuth(mediaUrl);
      }
      setStartGetMedia(false);
    } catch (e) {
      if (isRetry === false) {
        setIsRetry(true);
        try {
          await fetchMediaData(unsafeRecordUrl);
        } catch (err) {
          console.log('fetch media error', err);
          SModal.warning({
            title: 'Не удается воспроизвести запись',
            closable: true,
            okText: 'OK',
            onOk: () => {
              dispatch(setUpdatingUrl(true));
              setStartGetMedia(true);
              setLoadAudio(false);
            },
            content: (
              <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
                <Col span={24}>
                  <Text>
                    Не удалось воспроизвести запись. Перейдите по следующей ссылке, если звонок не
                    воспроизводится: <b>проверьте настройки</b> телефонии.
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    copyable={{
                      text: unsafeRecordUrl,
                      tooltips: [
                        t('constants.errors.loadingRecordError.tooltip.copy'),
                        t('constants.errors.loadingRecordError.tooltip.copied')
                      ]
                    }}
                    className="truncated"
                  >
                    <a href={unsafeRecordUrl} target="_blank">
                      {truncateString(unsafeRecordUrl, 40)}
                    </a>
                  </Text>
                </Col>
              </Row>
            )
          });
        }
      }
    }
  };

  useEffect(() => {
    const setInitState = async () => {
      const { search } = history.location;
      if (search) {
        const { t } = qs.parse(search, { ignoreQueryPrefix: true });
        if (t) {
          setTime(parseFloat(t));
          dispatch(
            updatePlayerState({
              playedSeconds: parseFloat(t),
              wasPlayed: false
            })
          );
        }
      }
    };
    setInitState();
  }, [history.location]);

  useEffect(() => {
    if (forcePlayed) {
      setTime(playedSeconds);
      dispatch(updatePlayerState({ forcePlayed: false }));
    }
  }, [playedSeconds]);

  const handleProgress = useCallback(
    async ({ playedSeconds, played, loaded }) => {
      if (playedSeconds === 0 && !wasPlayed) {
        return;
      }
      await dispatch(updatePlayerState({ playedSeconds, played }));
      setBufferedPercentage(loaded * 100);
    },
    [dispatch]
  );

  const showErrorModal = () => {
    return SModal.warning({
      title: 'Не удается воспроизвести запись',
      closable: true,
      okText: 'OK',
      content: (
        <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
          <Col span={24}>
            <Text>
              Не удалось воспроизвести запись. Перейдите по следующей ссылке, если звонок не
              воспроизводится:
              <b>проверьте настройки</b>
              телефонии.
            </Text>
          </Col>
          <Col span={24}>
            <Text
              copyable={{
                text: unsafeRecordUrl,
                tooltips: [
                  t('constants.errors.loadingRecordError.tooltip.copy'),
                  t('constants.errors.loadingRecordError.tooltip.copied')
                ]
              }}
              className="truncated"
            >
              <a href={unsafeRecordUrl} target="_blank" rel="noreferrer">
                {truncateString(unsafeRecordUrl, 40)}
              </a>
            </Text>
          </Col>
        </Row>
      )
    });
  };

  const setInitialState = () => {
    setStartGetMedia(true);
    dispatch(setUrl(null));
    dispatch(setUpdatingUrl(true));
    setPrevUrl(null);
    setLoadAudio(false);
    setAuth(null);
    dispatch(updatePlayerState({ isPlaying: false }));
  };

  const initSocket = ({ isReload = false }) => {
    dispatch(setUpdatingUrl(true));
    if (isVideo(mediaUrl)) {
      fetchMediaData(mediaUrl);
    }
    const audioServiceOriginUrl = new URL(audioServiceOrigin);
    const socket = openSocket(audioServiceOriginUrl.origin, {
      reconnectionAttempts: 5,
      path: nodePath.join(audioServiceOriginUrl.pathname, 'socket.io')
    });
    socket.on('connect_error', () => {
      console.log('connect_error');
      dispatch(setUrl(null));
    });
    socket.on('connect_failed', () => {
      console.log('connect_failed');
      dispatch(setUrl(null));
    });
    socket.on('disconnect', () => {
      console.log('disconnect');
      dispatch(setUrl(null));
    });
    socket.on('record trade error', () => {
      console.log('record trade error');
      if (isVideo(mediaUrl)) {
        return;
      }
      dispatch(setUrl(null));
      setStartGetMedia(true);
      if (url || unsafeRecordUrl) {
        fetchMediaData(url || unsafeRecordUrl);
      } else {
        setInitialState();
        showErrorModal();
      }
    });
    socket.emit('trade record url', { recordUrl: unsafeRecordUrl, callId: call.id });

    socket.on('traded record', async ({ path }) => {
      const audioServiceRecordUrl = new URL(
        nodePath.join(audioServiceOriginUrl.pathname, path),
        audioServiceOriginUrl.origin
      );
      dispatch(setUrl(audioServiceRecordUrl.href));
      await fetchMediaData(audioServiceRecordUrl.href);
      if (playedSeconds && isReload) setTime(playedSeconds);
    });

    return socket;
  };

  useEffect(() => {
    if (!isEmpty(prevUrl) && prevUrl === url) {
      return;
    }
    if (!loadAudio) {
      return;
    }
    const socket = initSocket({ isReload: false });
    return () => {
      socket.emit('leave player page');
      setStartGetMedia(true);
      dispatch(setUrl(null));
      dispatch(setUpdatingUrl(true));
      setPrevUrl(null);
      setLoadAudio(false);
      setAuth(null);
      dispatch(updatePlayerState({ isPlaying: false }));
    };
  }, [unsafeRecordUrl, call.id, loadAudio]);

  const onReady = () => {
    setPrevUrl(url || unsafeRecordUrl);
    setLoadDuration(false);
  };

  const onPlay = () => {
    dispatch(updatePlayerState({ wasPlayed: true }));
    // ! remove autoplay attr from audio tag cuz it can cause double sound on some devices
    try {
      const audio = document.querySelector('audio');
      if (audio) {
        audio.removeAttribute('autoplay');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Метод который который в тихом режиме пробует достучаться по всем ссылкам к аудио иначе выводит ошибку
  const handleMediaError = async attempt => {
    if (attempt === 1) {
      // Попытка с URL
      await fetchMediaData(url);
    } else if (attempt === 2) {
      // Попытка с unsafeRecordUrl
      await fetchMediaData(unsafeRecordUrl);
    } else {
      console.log('All attempts failed');
      setInitialState();
      showErrorModal();
    }
  };

  // Срабатывает при выдачи ошибки плеером
  const onError = async error => {
    if (isRetry === false) {
      setIsRetry(true);
      return handleMediaError(1);
    }

    if (isRetry === true) {
      setIsRetry(false);
      return handleMediaError(2);
    }

    console.log('player error', error);
    showErrorModal();
  };

  // console.log('updatingUrl', updatingUrl, '\nstartGetMedia', startGetMedia, '\nloadAudio', loadAudio);
  // console.log('auth', auth, '\nunsafeRecordUrl', unsafeRecordUrl, '\nurl', url);

  return (
    <div>
      {/* Ожидаем получения url */}
      {updatingUrl && startGetMedia && (
        <Row
          type="flex"
          justify="center"
          align="middle"
          gutter={[8, 8]}
          style={{ margin: '-4px', height: '80px', width: '100%', textAlign: 'center' }}
        >
          <Col>
            {!loadAudio ? (
              <Text>{t('components.recordPlayer.startLoadingAudio')}</Text>
            ) : (
              <Spin spinning tip={t('components.recordPlayer.loading')} />
            )}
          </Col>
        </Row>
      )}
      {/* url получен и кнопка воспроизведения не нажата */}
      {!updatingUrl && startGetMedia && !loadAudio && (
        <Row
          type="flex"
          justify="center"
          align="middle"
          gutter={[8, 8]}
          style={{ margin: '-4px', height: '80px', width: '100%', textAlign: 'center' }}
        >
          <Col>
            {!loadAudio ? (
              <Text>{t('components.recordPlayer.startLoadingAudio')}</Text>
            ) : (
              <Spin spinning tip={t('components.recordPlayer.loading')} />
            )}
          </Col>
        </Row>
      )}
      {/* url получен и кнопка воспроизведения нажата */}
      {!updatingUrl && !startGetMedia && loadAudio && (
        <>
          <Player
            url={auth || url || unsafeRecordUrl}
            onProgress={handleProgress}
            style={{ display: isVideo(auth) ? 'block' : 'none' }}
            onDuration={duration => dispatch(updatePlayerState({ duration }))}
            loop={false}
            playing={isPlaying}
            playbackRate={parseFloat(playbackRate)}
            progressInterval={100}
            ref={player}
            onReady={onReady}
            onError={onError}
            onPlay={onPlay}
            width={isVideo(auth) ? '100%' : 0}
            height={isVideo(auth) ? '700px' : 0}
          />
          <AudioTrack
            comments={comments}
            setTime={setTime}
            messages={messages}
            onMessageClick={onMessageClick}
            bufferedPercentage={bufferedPercentage}
          />
        </>
      )}
      {/* Кнопки под плеером (отображаются всегда без ожидания полной загрузки аудио) */}
      <RecordControls
        loadDuration={loadDuration}
        setLoadDuration={setLoadDuration}
        call={call}
        reviewId={review ? review.id : null}
        setTime={setTime}
        allowAttachTags={allowAttachTags}
        showTags={showTags}
        shouldLoad={shouldLoad}
        recordUrl={url || unsafeRecordUrl}
        reloadFunction={() => {
          initSocket({ isReload: true });
        }}
        loadAudio={loadAudio}
        setLoadAudio={setLoadAudio}
        fromNewReview={fromNewReview}
      />
    </div>
  );
};

export default React.memo(RecordPlayer, isEqual);
